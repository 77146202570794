import * as React from 'react';
import {useState, ChangeEvent} from 'react';
import {
    ShowBase,
    ReferenceManyField,
    useShowContext,
    Datagrid,
    TextField,
    ReferenceField,
    List,
    useRecordContext,
    Pagination, FunctionField, SingleFieldList, ChipField, UrlField, ListGuesser, Resource, DateField
} from 'react-admin';

import {
    Box,
    Card,
    CardContent,
    Tabs,
    Tab,
    Divider, Stack, Button, Typography,
} from '@mui/material';
import {Link, Link as RouterLink} from 'react-router-dom';

import {ConversionsAside} from './ConversionsAside';
import {Users} from '../types';
import {HashAvatarIcon, PokeAvatarIcon} from "../HashAvatarIcon";
import {ConversionsBase} from "../types";
import TitleBarImageList from "./Images";

export const ConversionsShow = () => (
    <ShowBase>
        <ConversionsShowContent/>
    </ShowBase>
);

const ConversionsShowContent = () => {

    const {record: packageBase, isLoading} = useShowContext<ConversionsBase>();
    const [tabValue, setTabValue] = useState(0);
    const handleTabChange = (event: ChangeEvent<{}>, newValue: number) => {
        setTabValue(newValue);
    };
    // console.log(record);
    if (!packageBase) return null;
    return (
        <Box mt={2} display="flex">
            <Box flex="1">
                <Card>
                    <CardContent>
                        <Tabs
                            value={tabValue}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={handleTabChange}>
                            {/*{*/}
                            {/*    <Tab*/}
                            {/*        label={"授权信息"}*/}
                            {/*    />*/}

                            {/*}*/}
                            {
                                <Tab
                                    label={"资源预览"}
                                />

                            }
                            {
                                <Tab
                                    label={"测试"}
                                />

                            }

                        </Tabs>
                        <Divider/>

                        <TabPanel value={tabValue} index={0}>
                            <ReferenceManyField
                                reference={"ConversionsPackage"}
                                perPage={15}
                                pagination={<Pagination rowsPerPageOptions={[15, 30, 60, 120]}/>}
                                filter={{search: true, category: packageBase.id.toString()}}
                                target="id"
                                source="id"
                                sort={{field: 'updateTime', order: 'DESC'}}
                            >
                                <Datagrid rowClick="edit">

                                    <FunctionField label={"预览图"} sx={{height: 50}} render={(record: any) => {
                                        return (<PokeAvatarIcon {...record}/>);
                                    }
                                    }/>
                                    <FunctionField label={"id/key"} render={(record: any) => {
                                        return (
                                            <Typography component={"div"} variant="body2" color="text.secondary">
                                                {record.id}
                                                <Divider/>
                                                {record.key}
                                            </Typography>
                                        )
                                    }}/>

                                    <FunctionField render={(record: any) => {
                                        if (record.group == "default") {
                                            return null;
                                        }
                                        return (<ChipField source="group"/>);
                                    }}/>

                                    <FunctionField render={(record: any) => {
                                        if (!record.alias) {
                                            return null;
                                        }
                                        return (<ChipField source="alias"/>);
                                    }}/>

                                    <FunctionField label={"value/path"} render={(packages: any) => {
                                        return (<Typography component={"div"} variant="body2" color="text.secondary">
                                            {packages.value}
                                            <Divider/>
                                            {packages.path}
                                        </Typography>)
                                    }}/>
                                    <FunctionField label={"vol/keyMd5/valueMd5"} render={(packages: any) => {
                                        return (<Typography component={"div"} variant="body2" color="text.secondary">
                                            {packages.vol}
                                            <Divider/>
                                            {packages.keyMd5}
                                            <Divider/>
                                            {packages.valueMd5}
                                        </Typography>)
                                    }}/>
                                    {/*<TextField source="describe"/>*/}
                                </Datagrid>
                            </ReferenceManyField>
                        </TabPanel>
                        <TabPanel index={tabValue} value={1}>
                                <TitleBarImageList/>
                        </TabPanel>
                    </CardContent>
                </Card>
            </Box>
            <ConversionsAside/>
        </Box>
    );
};

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

const TabPanel = (props: TabPanelProps) => {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            aria-labelledby={`wrapped-tab-${index}`}
            {...other}
        >
            {children}
        </div>
    );
};


