import InvoiceIcon from '@mui/icons-material/LibraryBooks';

import GroupList from './GroupList';
import {EditGuesser} from "react-admin";
import InterfaceShow from "./InterfaceShow";
import {GroupSettingEdit,GroupSettingCreate} from "./GroupEdit";

export default {
    list: GroupList,
    create:GroupSettingCreate,
    edit:GroupSettingEdit,
    show:InterfaceShow,
    icon: InvoiceIcon,
};
