import * as React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    ReferenceField,
    NumberField,
    DateInput,
    FunctionField,
    BooleanField, SelectField,
    SelectInput, Pagination,
} from 'react-admin';

import ScoreShow from './ScoreShow';
import {GroupUserAvatarIcon, HashAvatarIcon} from "../HashAvatarIcon";
import {Chip, Divider, Tooltip, Typography} from "@mui/material";
import {Users, SigIn} from "../types"
import DarkModeIcon from "@mui/icons-material/DarkMode";
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
//结算
import DiamondIcon from '@mui/icons-material/Diamond';
//灯泡
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
//speck
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
//sigin
import PersonPinIcon from '@mui/icons-material/PersonPin';
//war
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';


import ShoppingCartSharpIcon from '@mui/icons-material/ShoppingCartSharp';
import {blue, pink, red, yellow} from "@mui/material/colors";
import {WarRendering} from "../war/WarList";

export const sectors = [
    {id: '1', name: '聊天'},
    {id: '2', name: '签到'},
    {id: '3', name: '战队赛'},
    {id: '4', name: '赛季结算'},
    {id: '5', name: '购买'},
    {id: '6', name: '答题'},
    {id: '7', name: '内战'},
    {id: '8', name: '红包'},
    {id: '9', name: '盾反'},
    {id: '100', name: '其他'},
];

const listFilters = [
    <SelectInput
        alwaysOn
        source="category"
        label={"类型"}
        choices={sectors}
        optionText={(choice: any) => choice.name}
    />,
];

const ScoreList = () => {
    return (
        <List
            filters={listFilters}
            perPage={15}
            pagination={<Pagination rowsPerPageOptions={[15, 30, 60, 120]}/>}
            filterDefaultValues={{search: true}}
            sort={{field: 'signInTime', order: 'desc'}}
        >
            <ScoreRendering/>
        </List>
    );
};

export const ScoreRendering = () => {
    return <Datagrid bulkActionButtons={false} rowClick="edit">
        {/*<TextField source="id" />*/}

        {/*<ReferenceField label={"用户"} source="userId" link={"show"} reference="users">*/}
        {/*    <FunctionField render={(record: Users) => {*/}
        {/*        return (*/}
        {/*            <Typography variant="body2" color="text.secondary">*/}
        {/*                {(record.card ?? record.nickname)}*/}
        {/*                {record.id}*/}
        {/*            </Typography>*/}
        {/*        )*/}
        {/*    }}/>*/}
        {/*</ReferenceField>*/}

        <ReferenceField source={"userId"} reference={"users"} link={"show"}>
            <FunctionField render={(record: any) => {
                return <Chip label={record.card} icon={<GroupUserAvatarIcon {...record}/>}/>
            }}/>
        </ReferenceField>


        <FunctionField label={"时间"} render={(record: any) => {
            return (<>
                <Chip size="small" icon={<DarkModeIcon/>} label={record.moon}/>
                <br/>
                <DateField showTime={true} source={"signInTime"}/>
            </>)
        }}/>

        <FunctionField label={"积分"} render={(record: any) => {
            return <Chip size="small" icon={<CurrencyBitcoinIcon/>}
                         color={(record.score > 0 ? 'info' : "error")} variant="outlined"
                         sx={{color: (record.score > 0 ? blue[400] : red[400])}} label={record.score}/>
        }
        }/>

        <FunctionField label={"连续/排行"} render={(packages: any) => {
            return (<Typography variant="body2" color="text.secondary">
                {packages.continuousTimes}/ {packages.orderBy}
            </Typography>)
        }}/>

        <FunctionField render={(record: SigIn) => {
            if (record.category === 1) {
                return <Chip size="small" icon={<KeyboardVoiceIcon/>} label={"聊天"}/>
            } else if (record.category === 2) {
                return <Chip size="small" icon={<PersonPinIcon/>} label={"签到"}/>
            } else if (record.category === 3) {
                return <Chip size="small" icon={<RocketLaunchIcon/>} label={"战队赛"}/>
            } else if (record.category === 4) {
                return <Chip size="small" icon={<DiamondIcon/>} label={"赛季结算"}/>
            } else if (record.category === 5) {
                return <Chip size="small" sx={{color: pink[500]}} icon={<ShoppingCartSharpIcon/>} label={"购买"}/>
            } else if (record.category === 6) {
                return <Chip size="small" icon={<EmojiObjectsIcon/>} label={"答题"}/>
            } else if (record.category === 7) {
                return '内战积分'
            } else if (record.category === 8) {
                return '红包卡'
            } else if (record.category === 9) {
                return '盾反返还'
            } else {
                return '其他'
            }
        }}/>

        <FunctionField label="描述"
                       render={(record: any) => <Tooltip
                           title={record.describe}>
                           <span>{(record.describe.substring(0, 15)) + (record.describe.length > 15 ? "..." : "")}</span>
                       </Tooltip>}/>
        {/*<BooleanField source="max"/>*/}
    </Datagrid>
}

export default ScoreList;
