import * as React from 'react';
import {Create, Form, Toolbar} from 'react-admin';
import BusinessIcon from '@mui/icons-material/Business';
import {CardContent, Stack, Avatar, Box, Card, CardActionArea, CardMedia, Typography, Divider} from '@mui/material';

import {UserForm} from './UserForm';

export const UserCreate = () => (
    <Create actions={false} redirect="show">
        <Form>
            <CardContent>
                <Stack direction="row">

                    <Box ml={2} flex="1" maxWidth={796}>
                        <UserForm/>
                    </Box>
                    <Card sx={{maxWidth: 400, maxHeight: 600}}>
                    <CardActionArea>
                        <CardMedia
                            component="img"
                            height="400"
                            image="/logos/application.png"
                            alt="green iguana"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                应用
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                1、应用是一个或多个【数据源】、一个或多个【接口】组成，并直接向外提供服务的入口。
                                <Divider/>
                                2、应用可以被多个用户共享。
                                <Divider/>
                                3、应用ID和uri是请求的唯一标识符。
                            </Typography>
                        </CardContent>

                    </CardActionArea>
                </Card>
                </Stack>
            </CardContent>
            <Toolbar/>
        </Form>
    </Create>
);
