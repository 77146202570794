import InvoiceIcon from '@mui/icons-material/LibraryBooks';

import InterfaceList from './WarList';
import {ListGuesser} from "react-admin";



export default {
    list: InterfaceList,
    icon: InvoiceIcon,
};
