import * as React from 'react';
import {useState, ChangeEvent} from 'react';
import {
    ShowBase,
    ReferenceManyField,
    useShowContext,
    Datagrid,
    TextField,
    ReferenceField,
    List,
    Pagination, FunctionField, SingleFieldList, ChipField, UrlField, ListGuesser, Resource, DateField
} from 'react-admin';

import {
    Box,
    Card,
    CardContent,
    Tabs,
    Tab,
    Divider, Stack, Button,
} from '@mui/material';
import {Link, Link as RouterLink} from 'react-router-dom';

import {UserAside} from './UserAside';
import {Users} from '../types';
import {HashAvatarIcon} from "../HashAvatarIcon";

import {Builders} from '../speaks/SpeakList';
import WarList, {WarRendering} from "../war/WarList";
import {ScoreRendering} from "../signIn/ScoreList";
import {KnapsackRender} from "../knapsack/knapsackList";


export const UserShow = () => (
    <ShowBase>
        <UserShowContent/>
    </ShowBase>
);

const UserShowContent = () => {

    const {record, isLoading} = useShowContext<Users>();
    const [tabValue, setTabValue] = useState(0);
    const handleTabChange = (event: ChangeEvent<{}>, newValue: number) => {
        setTabValue(newValue);
    };
    // console.log(record);
    if (!record) return null;
    return (
        <Box mt={2} display="flex">
            <Box flex="1">
                <Card>
                    <CardContent>
                        <Tabs
                            value={tabValue}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={handleTabChange}>
                            {
                                <Tab
                                    label={"发言"}
                                />
                            }
                            {
                                <Tab
                                    label={"战队赛"}
                                />
                            }
                            {
                                <Tab
                                    label={"积分"}
                                />
                            }
                            {
                                <Tab
                                    label={"签到"}
                                />
                            }
                            {
                                <Tab
                                    label={"物品"}
                                />
                            }
                            {
                                <Tab
                                    label={"排行"}
                                />
                            }
                        </Tabs>
                        <Divider/>
                        <TabPanel value={tabValue} index={0}>
                            <ReferenceManyField
                                reference={"MessageRealScrolling/" + record.id}
                                filter={{search: true}}
                                target="id"
                                source="id"
                                perPage={15}
                                pagination={<Pagination rowsPerPageOptions={[15, 30, 60, 120]}/>}
                                sort={{field: 'updateTime', order: 'DESC'}}
                            >
                                <Builders disableUserid={true} disableType={true} textLength={50}/>
                            </ReferenceManyField>
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                            <ReferenceManyField
                                reference={"war"}
                                filter={{search: true, userId: record.id}}
                                target="id"
                                source="id"
                                perPage={15}
                                pagination={<Pagination rowsPerPageOptions={[15, 30, 60, 120]}/>}
                                sort={{field: 'modifyTime', order: 'desc'}}
                            >
                                <WarRendering disWar={true}/>
                            </ReferenceManyField>
                        </TabPanel>
                        <TabPanel value={tabValue} index={2}>
                            <ReferenceManyField
                                reference={"signIn"}
                                filter={{search: true, userId: record.id}}
                                target="id"
                                source="id"
                                perPage={15}
                                pagination={<Pagination rowsPerPageOptions={[15, 30, 60, 120]}/>}
                                sort={{field: 'signInTime', order: 'desc'}}
                            >
                                <ScoreRendering/>
                            </ReferenceManyField>
                        </TabPanel>
                        <TabPanel value={tabValue} index={3}>
                            <ReferenceManyField
                                reference={"signIn"}
                                filter={{search: true, userId: record.id, category: 2}}
                                target="id"
                                source="id"
                                perPage={15}
                                pagination={<Pagination rowsPerPageOptions={[15, 30, 60, 120]}/>}
                                sort={{field: 'signInTime', order: 'desc'}}
                            >
                                <ScoreRendering/>
                            </ReferenceManyField>
                        </TabPanel>
                        <TabPanel value={tabValue} index={4}>
                            <ReferenceManyField
                                reference={"knapsack"}
                                filter={{search: true, userId: record.id}}
                                target="id"
                                source="id"
                                perPage={15}
                                pagination={<Pagination rowsPerPageOptions={[15, 30, 60, 120]}/>}
                                sort={{field: 'signInTime', order: 'desc'}}
                            >
                                <KnapsackRender/>
                            </ReferenceManyField>
                        </TabPanel>
                    </CardContent>
                </Card>
            </Box>
            <UserAside/>
        </Box>
    );
};

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

const TabPanel = (props: TabPanelProps) => {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            aria-labelledby={`wrapped-tab-${index}`}
            {...other}
        >
            {children}
        </div>
    );
};


