import * as React from 'react';
import Box from '@mui/material/Box';
import {DataGrid, GridColDef, GridValueGetterParams} from '@mui/x-data-grid';
import {useGetList} from "react-admin";
import {CardMedia} from "@mui/material";
import {getJFSC} from "../common/group";

const columns: GridColDef[] = [
    {
        field: 'img', headerName: '', width: 100, renderCell: (params: GridValueGetterParams) => {
            let id = params.row.path;
            const path = getJFSC(id);
            // console.log(path);
            return (<CardMedia component="img" sx={{objectFit: "scale-down"}} height={"50"} image={path}/>)
        }
    },
    {field: 'title', headerName: '商品', width: 100},
    {field: 'describe', headerName: '描述', width: 200}
];

export default function TitleSelectData() {
    const {data: users} = useGetList("storeMenus/selectList");
    if (!users) {
        return null;
    }
    return (
        <Box sx={{height: 800, width: '100%'}}>
            <DataGrid
                sx={{height: 800, width: '100%'}}
                rows={users}
                columns={columns}
                pageSize={13}
                rowsPerPageOptions={[13]}
                checkboxSelection
                disableSelectionOnClick
            />
        </Box>
    );
}