import * as React from 'react';
import {Chip, Grid} from '@mui/material';
import WarSort from "./WarSort";
import {red, blue, yellow, pink, orange} from "@mui/material/colors";
import NoiseAwareIcon from '@mui/icons-material/NoiseAware';
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import FaceIcon from '@mui/icons-material/Face';

export const Dashboard = () => (
    <Grid container spacing={1} mt={1}>
        <Grid item md={3}>
            <WarSort source="war/sort"
                     // sortHeader={<span style={{color: red[500]}}>战队赛</span>}

                     sortHeader={<Chip icon={<NoiseAwareIcon />} label={"段位排行"} />}
                     valueHeader={<p>段</p>}
            />
            
        </Grid>
        <Grid item md={3}>
            <WarSort source="signin/sort"
                     sortHeader={<Chip icon={<CurrencyBitcoinIcon />} label={"积分排行"} />}
                     valueHeader={<p>分</p>}
            />
        </Grid>
        <Grid item md={3}>
            <WarSort source="signin/historySort"
                     sortHeader={<Chip icon={<CurrencyBitcoinIcon />} label={"历史积分排行"} />}
                     valueHeader={<p>分</p>}
            />
        </Grid>
        <Grid item md={3}>
            <WarSort source="speak/sort"
                     sortHeader={<Chip icon={<Brightness7Icon />} label={"发言排行"} />}
                     valueHeader={<p>话</p>}
            />
        </Grid>
        <Grid item md={3}>
            <WarSort source="signin/signinSort"
                     sortHeader={<Chip icon={<FaceIcon />} label={"签到排行"} />}
                     valueHeader={<p>次</p>}
            />
        </Grid>
        <Grid item md={3}>
            <WarSort source="war/sort" sortHeader={<p>历史积分</p>}
                     valueHeader={<p>分</p>}/>
        </Grid>
        <Grid item md={3}>
            <WarSort source="war/sort" sortHeader={<p>历史积分</p>}
                     valueHeader={<p>分</p>}/>
        </Grid>
        <Grid item md={3}>
            <WarSort source="war/sort" sortHeader={<p>历史积分</p>}
                     valueHeader={<p>分</p>}/>
        </Grid>
    </Grid>
);
