import { ConversionsList } from './ConversionsList';
import { ConversionsCreate } from './ConversionsCreate';
import { ConversionsShow } from './ConversionsShow';
import { ConversionsEdit } from './ConversionsEdit';
import { EditGuesser } from "react-admin"

export default {
    list: ConversionsList,
    create: ConversionsCreate,
    edit: ConversionsEdit,
    show: ConversionsShow,
};
