import * as React from 'react';
import {
    TextField,
    DateField,
    FunctionField,
    ReferenceField,
    EditButton,
    ShowButton,
    useRecordContext,
    ReferenceManyField,
    SingleFieldList,
    ChipField, useReference,
} from 'react-admin';

import {Box, Typography, Divider, Link, Button} from '@mui/material';

import {Users, Sale, Tenant} from '../types';
import {HashAvatarIcon} from "../HashAvatarIcon";

interface CompanyAsideProps {
    link?: string;
}

export const ConversionsPackageAside = ({link = 'edit'}: CompanyAsideProps) => {
    const record = useRecordContext<Users>();
    if (!record) return null;
    return (
        <Box ml={4} width={250} minWidth={250}>
            <Box textAlign="center" mb={2}>
                {link === 'edit' ? (
                    <EditButton label="编辑应用"/>
                ) : (
                    <ShowButton label="显示应用"/>
                )}
            </Box>

            <Typography variant="subtitle2">应用信息</Typography>
            <Divider/>
            <Box mt={2}>
                <Typography variant="body2">
                    应用id：{record.id}
                </Typography>
            </Box>
            <Divider/>
            {/*<Box mt={2}>*/}
            {/*    <Typography variant="body2"  >*/}
            {/*        {"租户数：" + record.tenantCount.toString()}*/}
            {/*    </Typography>*/}
            {/*    <Divider/>*/}

            {/*    <Typography variant="body2">*/}
            {/*        {"接口数：" + record.apiCount.toString()}*/}
            {/*    </Typography>*/}
            {/*</Box>*/}

            <Box mt={1}>
                <TextField source="phone_number"/>{' '}
                <Typography
                    variant="body2"
                    color="textSecondary"
                    component="span"
                >
                </Typography>
            </Box>

            <Box mt={1} mb={3}>
                <TextField source="address"/>
                <br/>
                <TextField source="city"/> <TextField source="zipcode"/>{' '}
                <TextField source="stateAbbr"/>
            </Box>

            <Typography variant="subtitle2">租户列表</Typography>
            <Divider/>

            {/*<Box mt={1}>*/}

            {/*    <ReferenceManyField*/}
            {/*        reference={"/tenant_app/" + record.id.toString() + "/getTenant"}*/}
            {/*        target={record.id.toString()}*/}
            {/*        // source={"/tenant/" + record.id.toString()}*/}

            {/*    >*/}
            {/*        <TenantList/>*/}

            {/*    </ReferenceManyField>*/}

            {/*</Box>*/}
        </Box>
    );
};
const TenantList = () => {
    return (
        <SingleFieldList resource={"/tenant"} linkType={"show"}>
            <ChipField source="name"/>
        </SingleFieldList>
    )
}

