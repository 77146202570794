import * as React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    ReferenceField,
    NumberField,
    DateInput,
    FunctionField,
    BooleanField,
    Pagination,
} from 'react-admin';

import {Chip, Divider, Typography, Tooltip} from "@mui/material";
import InterfaceShow from './InterfaceShow';
import {HashAvatarIcon} from "../HashAvatarIcon";
import {Users, SigIn} from "../types"
import DarkModeIcon from "@mui/icons-material/DarkMode";
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
//结算
import DiamondIcon from '@mui/icons-material/Diamond';
//灯泡
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
//speck
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
//sigin
import PersonPinIcon from '@mui/icons-material/PersonPin';
//war
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';


import ShoppingCartSharpIcon from '@mui/icons-material/ShoppingCartSharp';
import {blue, pink, red, yellow} from "@mui/material/colors";
import FavoriteIcon from '@mui/icons-material/Favorite';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import GroupsIcon from '@mui/icons-material/Groups';

const listFilters = [
    <DateInput source="date_gte" alwaysOn/>,
    <DateInput source="date_lte" alwaysOn/>,
];

const GroupList = () => {
    return (
        <List
            // filters={listFilters}
            perPage={15}
            pagination={<Pagination rowsPerPageOptions={[15, 30, 60, 120]}/>}
            filterDefaultValues={{search: true}}
            sort={{field: 'id', order: 'desc'}}
        >
            <Datagrid rowClick="edit">

                <FunctionField label={"群号"} render={(record: any) => {
                    return <Chip icon={<GroupsIcon/>} label={record.id}/>
                }}/>


                <FunctionField render={(record: any) => {
                    return <Tooltip title={record.welcome}>
                        <Chip  variant={"outlined"}
                            label={(record.welcome.length > 15 ? record.welcome.substring(0, 15) + "..." : record.welcome)}

                        /></Tooltip>
                }}/>


                <FunctionField render={(record: any) => {
                    return <Tooltip title={record.goOut}><Chip  variant={"outlined"}
                        label={(record.goOut.length > 15 ? record.goOut.substring(0, 15) + "..." : record.goOut)}
                    /></Tooltip>
                }}/>

                <FunctionField label="成员签" render={(record: any) => {
                    return <Chip icon={<FavoriteIcon/>} label={record.signalMemberCategory}/>
                }}/>
                <FunctionField label="主人签" render={(record: any) => {
                    return <Chip icon={<AccountBalanceIcon/>} label={record.signalMemberCategory}/>
                }}/>
                <TextField source="orderBy"/>
                <NumberField label="问答登记" source={"questionLevel"}/>

            </Datagrid>
        </List>
    );
};

export default GroupList;
