import * as React from 'react';

import {ReferenceInput, TextInput, SelectInput, required, SelectField, SelectArrayInput, Pagination} from 'react-admin';
import {Divider, Stack, Grid, Select, MenuItem} from '@mui/material';

export const sectors = [
    '普通应用',
    '特殊应用'
].map(sector => ({id: sector, name: sector}));

export const ConversionsPackageForm = () => (
        <>
            <Grid container spacing={6}>
                <Grid item xs={4}>
                    <TextInput source="description" label={"标题*"} helperText={false} placeholder={"天狼星数据源"} fullWidth/>
                </Grid>
                <Grid item xs={8}>
                    <TextInput source="appName" label={"应用唯一标示符"} validate={required()} placeholder={"sirius"} fullWidth/>
                </Grid>
                <Grid item xs={12}>
                    <ReferenceInput
                        fullwidth
                        source="metaIds"
                        reference="meta"
                        label={"数据源"}
                        perPage={15}
                        pagination={<Pagination rowsPerPageOptions={[15, 30, 60, 120]}/>}
                    ><SelectArrayInput fullWidth label={"数据源"} key={"id"} source="description" optionText="description"
                                          options={{autoWidth: true}}/>
                    </ReferenceInput>
                </Grid>
            </Grid>
    {/*<Stack direction="row">*/}
    {/*    <SelectInput*/}
    {/*        label={"类别"}*/}
    {/*        source="category"*/}
    {/*        choices={sectors}*/}
    {/*        sx={{width: 200}}*/}
    {/*    />*/}
    {/*</Stack>*/}

        </>
    )
;