import {stringify} from 'query-string'
import {
    fetchUtils, GET_LIST, GET_ONE, GET_MANY, GET_MANY_REFERENCE, CREATE, UPDATE, UPDATE_MANY, DELETE, DELETE_MANY,
} from 'react-admin'

function filter(str) { // 特殊字符转义
    str += ''; // 隐式转换
    str = str.replace(/%/g, '%25');
    str = str.replace(/\+/g, '%2B');
    str = str.replace(/ /g, '%20');
    str = str.replace(/\//g, '%2F');
    str = str.replace(/\?/g, '%3F');
    str = str.replace(/&/g, '%26');
    str = str.replace(/\=/g, '%3D');
    str = str.replace(/#/g, '%23');
    return str;
}

function formateObjToParamStr(paramObj) {
    const sdata = [];
    for (let attr in paramObj) {
        sdata.push(`${attr}=${filter(paramObj[attr] ?? "")}`);
    }
    return sdata.join('&');
}

/**
 * Maps react-admin queries to a REST API implemented using Java Spring Boot and Swagger
 *
 * @example
 * GET_LIST     => GET http://my.api.url/posts?page=0&pageSize=10
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts?id=1234&id=5678
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts
 * DELETE       => DELETE http://my.api.url/posts/123
 */
const sirius_sdk = (apiUrl, httpClient = fetchUtils.fetchJson) => {
    /**
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The data request params, depending on the type
     * @returns {Object} { url, options } The HTTP request parameters
     */
    const convertDataRequestToHTTP = (type, resource, params) => {
        let url = ''
        const options = {}

        // console.debug("#[REQUEST]# [TYPE]", type, "[RESOURCE]", resource, "[PARAMS]", params)

        switch (type) {
            case GET_MANY_REFERENCE:
            case GET_LIST: {
                const {page, perPage} = params.pagination
                //当指定搜索方案时
                if (!!params.filter.search) {
                    const searchInput = {pageIndex: page, pageSize: perPage, ...params.filter, sort: params.sort};
                    url = `${apiUrl}/${resource}/search`
                    options.body = JSON.stringify(searchInput)
                    options.method = 'POST'
                } else {
                    // debugger
                    if (params.filter.toString() !== '{}') {
                        //将对象params.filter转换为url参数
                        let input = formateObjToParamStr(params.filter)
                        url = `${apiUrl}/${resource}?${input}&pageIndex=${page}&pageSize=${perPage}`
                    } else {
                        url = `${apiUrl}/${resource}?pageIndex=${page}&pageSize=${perPage}`
                    }
                }
                break
            }
            case GET_ONE:
                url = `${apiUrl}/${resource}${params.id ? `/${params.id}` : ''}`
                break
            case GET_MANY: {
                let input = "ids=" + params.ids.join(',') + formateObjToParamStr(params.filter)
                url = `${apiUrl}/${resource}?${input}`
                break
            }
            case UPDATE:
                url = `${apiUrl}/${resource}${params.id ? `/${params.id}` : ''}`
                options.method = 'PUT'
                options.body = JSON.stringify(params.data)
                break
            case CREATE:
                url = `${apiUrl}/${resource}`
                options.method = 'POST'
                options.body = JSON.stringify(params.data)
                break
            case DELETE:
                url = `${apiUrl}/${resource}${params.id ? `/${params.id}` : ''}`
                options.method = 'DELETE'
                break
            default:
                throw new Error(`错误的调用了某些类型： ${type}`)
        }
        return {url, options}
    }

    /**
     * @param {Object} response HTTP response from fetch()
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The data request params, depending on the type
     * @returns {Object} Data response
     */
    const convertHTTPResponse = (response, type, resource, params) => {

        const {headers, json} = response
        const data = json.data;
        //console.log(headers)
        switch (type) {
            case GET_LIST:
            case GET_MANY:
            case GET_MANY_REFERENCE:
                //空数据
                if (!data) {
                    return {
                        data: data, total: 0,
                    }
                }
                //直接返回数据
                if (data.hasOwnProperty('total')) {
                    return {
                        data: data.records, total: data.total,
                    }
                } else {
                    const newVar = {
                        data: data, total: 10,
                    };
                    return newVar
                }
            case CREATE:
                return {data: {...params.data, ...data}};
            case DELETE:
                return {data: {}}
            case UPDATE:
                return {data: {...data, id: data.id}}
            default:
                return {data: data}
        }
    }

    /**
     * 返回一个可执行的方法，方便调用
     * @param {string} type Request type, e.g GET_LIST
     * @param {string} resource Resource name, e.g. "posts"
     * @param {Object} payload Request parameters. Depends on the request type
     * @returns {Promise} the Promise for a data response
     */
    return (type, resource, params) => {

        if (type === UPDATE_MANY) {
            return Promise.all(params.ids.map((id) => httpClient(`${apiUrl}/${resource}/${id}`, {
                method: 'PUT', body: JSON.stringify(params.data),
            }))).then((responses) => ({
                data: responses.map((response) => response.json),
            }))
        }
        if (type === DELETE_MANY) {
            return Promise.all(params.ids.map((id) => httpClient(`${apiUrl}/${resource}/${id}`, {
                method: 'DELETE',
            }))).then((responses) => ({
                data: responses.map((response) => response.json),
            }))
        }

        const {url, options} = convertDataRequestToHTTP(type, resource, params)
        return httpClient(url, options)
            .then((response) => convertHTTPResponse(response, type, resource, params), w => console.log(w))
    }
}
export default sirius_sdk
