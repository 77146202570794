import * as React from 'react';
import Box from '@mui/material/Box';
import {DataGrid, GridColDef, GridValueGetterParams} from '@mui/x-data-grid';
import {useGetList} from "react-admin";
import {GroupIconAvatarIcon, GroupUserAvatarIcon} from "../HashAvatarIcon";
import { getUserHead } from '../common/group';

const columns: GridColDef[] = [
    {
        field: 'id', headerName: 'QQ', width: 150, renderCell: (params: GridValueGetterParams) => {
            return GroupIconAvatarIcon(getUserHead(params.row.id), params.row.id, params.row.id, 20)
        }
    },
    {field: 'name', width: 150, headerName: '名字'},
];

export default function UserSelectData() {
    const {data: users} = useGetList("users/selectList");
    if (!users) {
        return null;
    }
    return (
        <Box sx={{height: 800, width: '100%'}}>
            <DataGrid
                sx={{height: 800, width: '100%'}}
                rows={users}
                columns={columns}
                pageSize={13}
                rowsPerPageOptions={[13]}
                checkboxSelection
                disableSelectionOnClick
            />
        </Box>
    );
}