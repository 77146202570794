import { ConversionsPackageList } from './ConversionsPackageList';
import { ConversionsPackageCreate } from './ConversionsPackageCreate';
import { ConversionsPackageShow } from './ConversionsPackageShow';
import { ConversionsPackageEdit } from './ConversionsPackageEdit';

export default {
    list: ConversionsPackageList,
    create: ConversionsPackageCreate,
    edit: ConversionsPackageEdit,
    show: ConversionsPackageShow,
};
