import React from 'react';
import {Tabs, Tab, Toolbar, AppBar, Box, Typography, createTheme} from '@mui/material';
import {Link, matchPath, useLocation} from 'react-router-dom';
import {UserMenu, Logout, LoadingIndicator, ToggleThemeButton, defaultTheme, RaThemeOptions} from 'react-admin';
import {orange} from "@mui/material/colors";
import HomeIcon from '@mui/icons-material/Home';
// import CloudCircleIcon from '@mui/icons-material/CloudCircle';
import AppsIcon from '@mui/icons-material/Apps';
import ModeStandbyIcon from '@mui/icons-material/ModeStandby';
import HttpIcon from '@mui/icons-material/Http';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import FlashOffIcon from '@mui/icons-material/FlashOff';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import ApiIcon from '@mui/icons-material/Api';
import GroupsIcon from '@mui/icons-material/Groups';
import conversionsPackage from "./package";

declare module '@mui/material/styles' {
    interface Theme {
        status: {
            danger: string;
        };
    }

    // 允许配置文件使用 `createTheme`
    interface ThemeOptions {
        status?: {
            danger?: string;
        };
    }
}

const darkTheme = createTheme({
    palette: {mode: 'dark'},
    status: {
        danger: orange[500],
    }
});

const Header = () => {
    const location = useLocation();

    let currentPath = '/';
    if (!!matchPath('/account/*', location.pathname)) {
        currentPath = '/account';
    } else if (!!matchPath('/knapsack/*', location.pathname)) {
        currentPath = '/knapsack';
    } else if (!!matchPath('/users/*', location.pathname)) {
        currentPath = '/users';
    } else if (!!matchPath('/loadBalance/*', location.pathname)) {
        currentPath = '/loadBalance';
    } else if (!!matchPath('/conversions/*', location.pathname)) {
        currentPath = '/conversions';
    } else if (!!matchPath('/conversionsPackage/*', location.pathname)) {
        currentPath = '/conversionsPackage';
    } else if (!!matchPath('/signIn/*', location.pathname)) {
        currentPath = '/signIn';
    } else if (!!matchPath('/storeMenus/*', location.pathname)) {
        currentPath = '/storeMenus';
    } else if (!!matchPath('/war/*', location.pathname)) {
        currentPath = '/war';
    } else if (!!matchPath('/speak/*', location.pathname)) {
        currentPath = '/speak';
    } else if (!!matchPath('/groupSettings/*', location.pathname)) {
        currentPath = '/groupSettings';
    } else if (!!matchPath('/cache/*', location.pathname)) {
        currentPath = '/cache';
    } else if (!!matchPath('/order/*', location.pathname)) {
        currentPath = '/order';
    } else if (!!matchPath('/speak/*', location.pathname)) {
        currentPath = '/speak';
    } else if (!!matchPath('/language/*', location.pathname)) {
        currentPath = '/language';
    }

    return (
        <Box component="nav" sx={{flexGrow: 1}}>
            <AppBar position="static" color="primary">
                <Toolbar variant="dense">
                    <Box flex={1} display="flex" justifyContent="space-between">
                        <Box display="flex" alignItems="center">
                            {/*<Box*/}
                            {/*    component="img"*/}
                            {/*    sx={{marginRight: '1em', height: 30}}*/}
                            {/*    src={*/}
                            {/*        'https://upload.wikimedia.org/wikipedia/commons/a/a7/React-icon.svg'*/}
                            {/*    }*/}
                            {/*    alt="Bosch Logo"*/}
                            {/*/> */}
                            <ToggleThemeButton
                                lightTheme={defaultTheme}
                                darkTheme={darkTheme as any}
                            />
                            <Typography component="span" variant="h5">
                                SIRIUS
                            </Typography>
                        </Box>
                        <Box
                            alignItems="center">
                            <Tabs
                                centered={true}
                                value={currentPath}
                                aria-label="Navigation Tabs"
                                indicatorColor="secondary"
                                textColor="inherit"
                            >
                                <Tab
                                    sx={{h: '30px'}}
                                    // icon={<HomeIcon/>}
                                    label={'首页'}
                                    iconPosition="start"
                                    component={Link}
                                    to="/"
                                    value="/"/>
                                {/*<Tab*/}
                                {/*    label={'排行'}*/}
                                {/*    // icon={<AppsIcon/>}*/}
                                {/*    component={Link}*/}
                                {/*    iconPosition="start"*/}
                                {/*    to="/order"*/}
                                {/*    value="/order"/>*/}
                                <Tab
                                    label={'用户'}
                                    // icon={<AppsIcon/>}
                                    component={Link}
                                    iconPosition="start"
                                    to="/users"
                                    value="/users"/>
                                <Tab
                                    label={'比赛'}
                                    // icon={<AppsIcon/>}
                                    component={Link}
                                    iconPosition="start"
                                    to="/war"
                                    value="/war"/>
                                <Tab
                                    label={'负载'}
                                    // icon={<ModeStandbyIcon/>}
                                    iconPosition="start"
                                    component={Link}
                                    to="/loadBalance"
                                    value="/loadBalance"/>

                                <Tab
                                    label={'商城'}
                                    // icon={<SpeakerNotesIcon/>}
                                    component={Link}
                                    iconPosition="start"
                                    to="/storeMenus"
                                    value="/storeMenus"/>
                                <Tab
                                    label={'物品'}
                                    // icon={<ApiIcon/>}
                                    component={Link}
                                    iconPosition="start"
                                    to="/knapsack"
                                    value="/knapsack"/>
                                <Tab
                                    label={'积分'}
                                    // icon={<FlashOnIcon/>}
                                    component={Link}
                                    iconPosition="start"
                                    to="/signIn"
                                    value="/signIn"/>
                                <Tab
                                    label={'资源'}
                                    // icon={<GroupsIcon/>}
                                    component={Link}
                                    iconPosition="start"
                                    to="/conversions"
                                    value="/conversions"/>
                                <Tab
                                    label={'卡片'}
                                    // icon={<GroupsIcon/>}
                                    component={Link}
                                    iconPosition="start"
                                    to="/conversionsPackage"
                                    value="/conversionsPackage"/>
                                <Tab
                                    label={'群管'}
                                    // icon={<GroupsIcon/>}
                                    component={Link}
                                    iconPosition="start"
                                    to="/groupSettings"
                                    value="/groupSettings"/>
                                {/*<Tab*/}
                                {/*    label={'缓存'}*/}
                                {/*    // icon={<GroupsIcon/>}*/}
                                {/*    component={Link}*/}
                                {/*    iconPosition="start"*/}
                                {/*    to="/cache"*/}
                                {/*    value="/cache"/>*/}
                                <Tab
                                    label={'发言'}
                                    // icon={<GroupsIcon/>}
                                    component={Link}
                                    iconPosition="start"
                                    to="/speak"
                                    value="/speak"/>
                                {/*<Tab*/}
                                {/*    label={'语言包'}*/}
                                {/*    // icon={<GroupsIcon/>}*/}
                                {/*    component={Link}*/}
                                {/*    iconPosition="start"*/}
                                {/*    to="/language"*/}
                                {/*    value="/language"/>*/}
                            </Tabs>
                        </Box>

                        <Box display="flex">
                            <LoadingIndicator
                                sx={{
                                    '& .RaLoadingIndicator-loader': {
                                        marginTop: 2,
                                    },
                                }}
                            />

                            <UserMenu>
                                <Logout/>
                            </UserMenu>
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
        </Box>
    );
};

export default Header;
