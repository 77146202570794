import { UserList } from './UserList';
import { UserCreate } from './UserCreate';
import { UserShow } from './UserShow';
import { UserEdit } from './UserEdit';

export default {
    list: UserList,
    create: UserCreate,
    edit: UserEdit,
    show: UserShow,
};
