import * as React from 'react';
import {
    FilterList,
    FilterLiveSearch,
    FilterListItem, ReferenceField,
    useGetIdentity, useGetMany, useGetList,
} from 'react-admin';
import {Box} from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';

import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import {ConversionsBase, Tag} from "../types";


export const ConversionsPackageListFilter = () => {
    const {data: sizes, isLoading: isLoadingRecordTags} = useGetList<ConversionsBase>(
        'conversionsPackage/getCategory', {filter: {}}
    );
    if (!sizes) return null;
    return (
        <Box width="13em" minWidth="13em" order={-1} mr={2} mt={7}>
            <FilterLiveSearch/>
            <FilterList label={"类别"} icon={<BusinessIcon/>}>
                {sizes && sizes.map(size => (
                    <FilterListItem
                        key={size.category}
                        label={size.category}
                        value={{category: size.category}}
                    />
                ))}
            </FilterList>


        </Box>
    );
};
