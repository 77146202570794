import * as React from 'react';
import {useState, ChangeEvent} from 'react';
import {
    ShowBase,
    ReferenceManyField,
    useShowContext,
    Datagrid,
    TextField,
    ReferenceField,
    List,
    useRecordContext,
    Pagination, FunctionField, SingleFieldList, ChipField, UrlField, ListGuesser, Resource, DateField
} from 'react-admin';

import {
    Box,
    Card,
    CardContent,
    Tabs,
    Tab,
    Divider, Stack, Button, Typography,
} from '@mui/material';
import {Link, Link as RouterLink} from 'react-router-dom';

import {ConversionsPackageAside} from './ConversionsPackageAside';
import {Users} from '../types';
import {HashAvatarIcon} from "../HashAvatarIcon";
import {ConversionsBase} from "../types";

export const ConversionsPackageShow = () => (
    <ShowBase>
        <ConversionsPackageShowContent/>
    </ShowBase>
);

const ConversionsPackageShowContent = () => {

    const {record: packageBase, isLoading} = useShowContext<ConversionsBase>();
    const [tabValue, setTabValue] = useState(0);
    const handleTabChange = (event: ChangeEvent<{}>, newValue: number) => {
        setTabValue(newValue);
    };
    // console.log(record);
    if (!packageBase) return null;
    return (
        <Box mt={2} display="flex">
            <Box flex="1">
                <Card>
                    <CardContent>
                        <Tabs
                            value={tabValue}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={handleTabChange}>
                            {/*{*/}
                            {/*    <Tab*/}
                            {/*        label={"授权信息"}*/}
                            {/*    />*/}

                            {/*}*/}
                            {
                                <Tab
                                    label={"数据源"}
                                />

                            }
                            {
                                <Tab
                                    label={"接口"}
                                />

                            }

                        </Tabs>
                        <Divider/>

                        <TabPanel value={tabValue} index={0}>
                            <ReferenceManyField
                                reference={"cconversionsPackage/"}
                                perPage={15}
                                pagination={<Pagination rowsPerPageOptions={[15, 30, 60, 120]}/>}
                                filter={{search: true, category: packageBase.id.toString()}}
                                target="id"
                                source="id"
                                sort={{field: 'updateTime', order: 'DESC'}}
                            >
                                <Datagrid optimized rowClick="edit">
                                    <FunctionField label={"id/key"} render={(record: any) => {
                                        return (
                                            <Typography variant="body2" color="text.secondary">
                                                {record.id}
                                                <Divider/>
                                                {record.key}
                                            </Typography>
                                        )
                                    }}/>
                                    <TextField source="group"/>
                                    <TextField source="alias"/>
                                    <FunctionField label={"value/path"} render={(packages: any) => {
                                        return (<Typography variant="body2" color="text.secondary">
                                            {packages.value}
                                            <Divider/>
                                            {packages.path}
                                        </Typography>)
                                    }}/>
                                    <FunctionField label={"vol/keyMd5/valueMd5"} render={(packages: any) => {
                                        return (<Typography variant="body2" color="text.secondary">
                                            {packages.vol}
                                            <Divider/>
                                            {packages.keyMd5}
                                            <Divider/>
                                            {packages.valueMd5}
                                        </Typography>)
                                    }}/>
                                    <TextField source="describe"/>
                                </Datagrid>
                            </ReferenceManyField>
                        </TabPanel>

                    </CardContent>
                </Card>
            </Box>
            <ConversionsPackageAside/>
        </Box>
    );
};

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

const TabPanel = (props: TabPanelProps) => {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            aria-labelledby={`wrapped-tab-${index}`}
            {...other}
        >
            {children}
        </div>
    );
};


