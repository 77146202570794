import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import {useGetList, useRecordContext} from "react-admin";
import {Box, Card, CardContent, Paper, Typography} from "@mui/material";

const times = (nbChildren: number, fn: (key: number) => any) =>
    Array.from({length: nbChildren}, (_, key) => fn(key));
const LoadingGridList = () => (
    <Box display="flex" flexWrap="wrap" width={1008} gap={1}>
        {times(15, key => (
            <Paper
                sx={{
                    height: 200,
                    width: 194,
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: 'grey[200]',
                }}
                key={key}
            />
        ))}
    </Box>
);
export default function TitleBarImageList() {
    let useRecord = useRecordContext();
    const {data, total, isLoading, error} = useGetList(
        `conversions/test`,
        {
            'filter': {'category': useRecord.category, 'type': 'order'}
        }
    );
    let itemData = data;
    if (isLoading || !itemData) {
        return <LoadingGridList/>;
    }
    console.log(itemData, data);
    return (
        <ImageList>
            <ImageListItem key="Subheader" cols={6}>
                <ListSubheader component="div">高速通道</ListSubheader>
            </ImageListItem>
            {itemData.filter(w => w.img.indexOf(".mp4") == -1).map((item: any) => (
                <ImageListItem key={item.img}>
                    <Card component="li" sx={{width: 200, height: 250, flexGrow: 1}}>
                        <img
                            style={{width: "100%", height: "100%", objectFit: "scale-down"}}
                            src={item.img}
                            srcSet={item.img}
                            alt={item.title}
                            loading="lazy"
                        />
                        <ImageListItemBar
                            title={item.title}
                        /> </Card>
                </ImageListItem>
            ))}
            {
                itemData.filter(w => w.img.indexOf(".mp4") != -1).map((item: any) => (
                    <Card component="li" sx={{width: 200, height: 250, flexGrow: 1}}>
                        <video
                            style={{width: "100%", height: "100%", objectFit: "fill"}}
                            autoPlay
                            loop
                            muted
                            poster="https://assets.codepen.io/6093409/river.jpg"
                        >
                            <source
                                src={item.img}
                                type="video/mp4"
                            />
                        </video>
                    </Card>
                ))
            }
        </ImageList>
    );
}