import {RaRecord} from "ra-core";
import {Avatar, CardMedia, Chip, Tooltip} from "@mui/material";
import * as React from "react";
import {ConversionsPackage, StoreMenus, Users} from "./types";
import conversions from "./packagebase";
import * as url from "url";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import ImageListItem from "@mui/material/ImageListItem";
import {useRecordContext} from "react-admin";
import {getJFSC, getUserHead, getUserHeadPath} from "./common/group";

interface SiriusAvatarIconProps extends RaRecord {
    iconType: string;
    size: string;
    category?: string;
}

interface JFSCAvatarIconProps extends StoreMenus {
    height: number;
    size: string;
}

interface GroupUserProps extends StoreMenus {
    size: number;
    showId: boolean;
}

export interface AvatarIconProps {
    url: string;
    title?: string;
    sTitle?: string;
}

export const HashAvatarIcon = (prop: SiriusAvatarIconProps | RaRecord) => {

    function hashcode(str: String) {
        let hash = 0, i, chr, len;
        if (str.length === 0) return hash;
        for (i = 0, len = str.length; i < len; i++) {
            chr = str.charCodeAt(i);
            hash = ((hash << 5) - hash) + chr;
            hash |= 0; // Convert to 32bit integer
        }
        return Math.abs(hash);
    }

    const {id: identity} = prop;
    const id = identity.toString();
    if (!id) return null;
    let path = "dbicon";
    let hash = hashcode(id) % 190;
    if (!!prop.iconType) {
        if (prop.iconType == "meta") {
            path = "source";
            hash = hashcode(id) % 84;
        } else if (prop.iconType == "application") {
            path = "app";
            hash = hashcode(id) % 52;
        }
    }
    return GroupAvatarIcon(path+ hash.toString(), id + '.png', prop.size ?? "medium");
};


export const GroupUserAvatarIcon = (prop: GroupUserProps | Users) => {
    const id = prop.user_id.toString() ?? prop.id.toString()
    if (prop.showId) {
        let userid = (prop.card || prop.nickname || prop.id).toString();
        return GroupIconAvatarIcon(getUserHead(id), userid, id, prop.size);
    }
    return GroupAvatarIcon(getUserHead(id), id, prop.size ?? 30);
};


/**
 * poke list
 * @param prop
 * @constructor
 */
export const JFSCAvatarIcon = (prop: JFSCAvatarIconProps | StoreMenus) => {
    const id = prop.path ?? prop.id.toString();
    const path = getJFSC(id);
    // console.log(path);
    return (<CardMedia component="img" height={prop.height ?? "300"} image={path}/>)
};

export const JFSCAvatarIcon2 = () => {
    let prop = useRecordContext<StoreMenus>();
    console.log(prop)
    const id = prop.path ?? prop.id.toString();
    const path = getJFSC(id);
    // console.log(path);
    return (<CardMedia component="img" height={prop.height ?? "120"} image={path}/>)
};


export const PokeAvatarIcon = (prop: ConversionsPackage) => {
    let path = `inernoro/group/image/packages/material/${prop.category}/${prop.path}`
    let mediaType = "img"
    switch (prop.category) {
        case "TC":
            path = `inernoro/group/image/voices/${prop.category}/${prop.path}`
            break;
    }
    // console.log(path);
    switch (prop.mediaType) {
        case "image":
        case "images":
        case "img":
            mediaType = "img";
            break;
        // case "voices":
        // case "voice":
        // case "audio":
        //     mediaType = "audio";
        //     break;
        default:
            mediaType = "img";
            path = "music.png"
            break;
    }
    return (<CardMedia component={mediaType as any} src={mediaType as any} height="80" image={path}/>)
};

/**
 * 头像
 * @param prop
 * @constructor
 */
export const GroupUserMaxAvatarIcon = (prop: GroupUserProps | Users) => {
    const id = prop.user_id.toString() ?? prop.id.toString()
    if (prop.showId) {
        let userid = (prop.card || prop.nickname || prop.id).toString();
        return MaxImageFull({
            url: getUserHead(id),
            title: userid,
            sTitle: (prop.nickname + "(" + prop.user_id + ")")
        });
    }
    return MaxImageFull({url: getUserHead(id), title: prop.user_id, sTitle: prop.user_id});
};

export const ImageUrl = (url: AvatarIconProps) => {
    return (<CardMedia component={"img"}
                       onError={() => "404.png"} src={"img"} height="160" image={url.url}/>)
}

export const MaxImageFull = (item: AvatarIconProps) => {

    return (<ImageListItem key={item.url}>
        <img
            onError={(e) => {

            }}
            src={item.url}
            srcSet={item.url}
            alt={item.sTitle}
            loading="lazy"
        />
        <ImageListItemBar
            title={item.title}
            subtitle={item.sTitle}
        />
    </ImageListItem>)
}

interface VolProp {
    vol: string;
}

export const VolAvatar = (vol: VolProp) => {
    let url = "inernoro/vol/" + vol.vol;
    if (url.indexOf(".jpg") == -1) {
        url = "package/nullPackage.jpg";
        console.log(url);
    }
    return <ImageUrl url={url}/>
}


export const GroupAvatarIcon = (url: string, alter?: string, size?: number) => {
    return <Avatar
        src={url}
        alt={alter}
        onError={() => "404.png"}
        sx={{
            bgcolor: 'aliceblue',
            '& img': {objectFit: 'contain'},
            width: size ?? "30",
            height: size ?? "30",
        }}
        imgProps={{className: 'small'}}
    />
}

export const GroupIconAvatarIcon = (url: string, value: string, title?: string, size?: number
) => {
    return (<Tooltip key={value} title={title ?? ""}>
        <Chip variant="outlined" label={value} avatar={<Avatar
            src={url}
            sx={{
                bgcolor: 'aliceblue',
                '& img': {objectFit: 'contain'},
                width: size ?? "30",
                height: size ?? "30",
            }}
            imgProps={{className: 'small'}}
        />}/></Tooltip>)
}

