import * as React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    ReferenceField,
    FunctionField,
    ChipField,
    ArrayField,
    SingleFieldList,
    Pagination,
    useGetList,
    TopToolbar,
    useUpdate,
    useListContext, useGetOne, useGetMany, FileField
} from 'react-admin';

import {Box, Button, Chip, Divider, Stack, Link, Typography} from "@mui/material";
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import BoltIcon from '@mui/icons-material/Bolt';
import {PostFilterForm} from "./NewForm";
import ContentFilter from '@mui/icons-material/FilterList';
import DownloadIcon from '@mui/icons-material/Download';
import IconButton from "@mui/material/IconButton";

import {httpClient} from "../dataProvider";
import {NavLink} from "react-router-dom";
import {useState} from "react";
import {GroupUserAvatarIcon} from "../HashAvatarIcon";

const PostFilterButton = () => {
    const {showFilter} = useListContext();
    return (
        <Button
            size="small"
            color="primary"
            onClick={() => showFilter("main", {})}
            startIcon={<ContentFilter/>}
        >
            Filter
        </Button>
    );
};

const ListActions = () => {
    const {data: weekTime} = useGetList("war/weekTime");
    const [update] = useUpdate();
    const {filterValues} = useListContext();
    if (!weekTime) {
        return null;
    }
    const downWarExcel = () => {
        console.log(filterValues);
        // update('war/download', {data: filterValues}).then(() => { });
        let source = '/group/war/download?wt=' + JSON.stringify(filterValues.weekTime);
        httpClient(source, {method: "GET",})
            .then(r => {

            })
    }
    return <Box width="100%">
        <TopToolbar>
            <Link
                href={`/group/war/download?dw=${filterValues.doubleWeek}&wt=${JSON.stringify(filterValues.weekTime ?? "")}`}><DownloadIcon/></Link>
            {/*<IconButton color={"primary"} aria-label="add to shopping cart" component={NavLink}*/}
            {/*            to={'/group/war/download?wt=' + JSON.stringify(filterValues.weekTime)}>*/}
            {/*    */}
            {/*</IconButton>*/}
            {/*<FileField source={'/group/war/download?wt=' + JSON.stringify(filterValues.weekTime)} title="download" />*/}
        </TopToolbar>
        <PostFilterForm weekTime={weekTime}/>
    </Box>
};

interface disableUser {
    disUser?: boolean | undefined;
    disWar?: boolean | undefined;
}

export const WarRendering = (prop: disableUser | undefined) => {
    return <Datagrid bulkActionButtons={false} rowClick="show">

        <FunctionField render={(packages: any) => {
            if (prop?.disUser) {
                return null;
            }
            packages.user_id = packages.userId;
            return (<Typography component={"div"} variant="body2" color="text.secondary">
                <ReferenceField source="userId" reference="users">
                    <FunctionField render={(users: any) => {
                        return <Chip label={users.card} icon={<GroupUserAvatarIcon {...packages}/>}/>
                    }}/>
                </ReferenceField>
            </Typography>)
        }}/>

        <ArrayField source="warList">
            <SingleFieldList>
                <FunctionField render={(packages: any) => {
                    return <ChipField size="small" source="result"
                                      color={packages.result == "胜" ? 'success' : 'info'}
                                      variant="outlined"/>
                }}/>
            </SingleFieldList>
        </ArrayField>

        <FunctionField render={(packages: any) => {
            if (prop?.disWar) {
                return null;
            }
            return (<Typography component={"div"} variant="body2" color="text.secondary">
                <Chip size="small" icon={<BoltIcon/>}
                      label={`${packages.victory}/${packages.bad}/${packages.count}`}/>
            </Typography>)
        }}/>


        {/*<TextField source="warUrl"/>*/}
        {/*<TextField source="levelUrl"/>*/}

        {/*<TextField source="warPath"/>*/}
        {/*<TextField source="levelPath"/>*/}

        <FunctionField label={"钱/级"} render={(packages: any) => {
            return (<Typography component={"div"} variant="body2" color="text.secondary">
                <Chip size="small" icon={<LocalAtmIcon/>} label={packages.money}/>
                <br/>
                <Chip size="small" icon={<Brightness7Icon/>} label={packages.level}/>
            </Typography>)
        }}/>

        <FunctionField label={"时间"} render={(packages: any) => {
            return (<Typography component={"div"} variant="body2" color="text.secondary">
                <Chip size="small" icon={<DarkModeIcon/>} label={packages.moon}/>
                <Divider/>
                <DateField source={"createTime"}/>
                {/*{packages.createTime}*/}
                {/*<DateField source={"createTime"} showTime={true}/>*/}
                {/*<Divider/>*/}
                {/*<DateField source={"modifyTime"} showTime={true}/>*/}
            </Typography>)
        }}/>

        <FunctionField label={"翻/连"} render={(packages: any) => {
            return (
                <Typography component={"div"} variant="body2" color="text.secondary">
                    {packages.doublePoke}|{packages.continuePoke}
                </Typography>)
        }}/>

        <FunctionField label={"更检付"} render={(packages: any) => {
            return (<Typography component={"div"} variant="body2" color="text.secondary">
                {packages.changeCount}/
                {(packages.checked ? "OK" : "-")}/
                {(packages.pay ? "OK" : "-")}
            </Typography>)
        }}/>
    </Datagrid>;
}

const WarList = (props: any) => {

    return (
        <List
            actions={<ListActions/>}
            perPage={15}
            pagination={<Pagination rowsPerPageOptions={[15, 30, 60, 120]}/>}
            filterDefaultValues={{search: true}}
            sort={{field: 'createTime', order: 'desc'}}
            component="div"
            {...props}
        >
            <WarRendering/>
        </List>
    );
};

export default WarList;
