import * as React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    ReferenceField,
    NumberField,
    DateInput,
    FunctionField,
    BooleanField,
    ArrayField,
    SingleFieldList,
    ChipField, useRecordContext
} from 'react-admin';

import LoadBalanceShow from './loadBalanceShow';
import {HashAvatarIcon} from "../HashAvatarIcon";
import {ElementType} from "react";
import ErrorIcon from '@mui/icons-material/Error';
import CellWifiIcon from '@mui/icons-material/CellWifi';
import {
    Chip,
    List as List2, ListItem, ListItemText, ListSubheader, Typography
} from "@mui/material";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import FavoriteIcon from '@mui/icons-material/Favorite';
import WifiIcon from '@mui/icons-material/Wifi';

const listFilters = [
    <DateInput source="date_gte" alwaysOn/>,
    <DateInput source="date_lte" alwaysOn/>,
];
export const LoadbalanceList = () => (
    <List>
        <Datagrid rowClick="edit">
            {/*<TextField source="id"/>*/}
            <ReferenceField source="userId" reference="users">
                <TextField source="nickname"/>
            </ReferenceField>
            <FunctionField render={(packages: any) => {
                return (
                    <Chip size="small" icon={<WifiIcon/>} label={`${packages.ip}:${packages.port}`}/>);
            }
            }/>
            {/*<NumberField source="category"/>*/}
            <TagsField source={"manageGroup"}/>
            <BooleanField source="enable"/>

            <FunctionField render={(packages: any) => {
                if (new Date(packages.startTime).toLocaleDateString() == new Date().toLocaleDateString()) {
                    return (<Chip size="small" icon={<FavoriteIcon/>} color={"primary"}
                                  label={`${new Date(packages.startTime).toLocaleString()}`}/>);
                } else {
                    return (<>{new Date(packages.startTime).toLocaleString()}</>);
                }
            }}/>

            <FunctionField render={(packages: any) => {
                if (new Date(packages.lastSendTime).toLocaleDateString() == new Date().toLocaleDateString()) {
                    return (<Chip size="small" icon={<FavoriteIcon/>} color={"success"}
                                  label={`${new Date(packages.lastSendTime).toLocaleString()}`}/>);
                } else {
                    return (<>{new Date(packages.lastSendTime).toLocaleString()}</>);
                }
            }}/>

            <FunctionField render={(packages: any) => {
                if (new Date(packages.lastErrorTime).toLocaleDateString() == new Date().toLocaleDateString()) {
                    return (<Chip size="small" icon={<ErrorIcon/>} color={"warning"}
                                  label={`${new Date(packages.lastErrorTime).toLocaleString()}`}/>);
                } else {
                    return (<>{new Date(packages.lastErrorTime).toLocaleString()}</>);
                }
            }}/>

            <TagsField source={"allGroup"}/>
        </Datagrid>
    </List>
);

interface TagsFieldProps {
    source: string;
}

const TagsField = (field: TagsFieldProps) => {
    const record: any = useRecordContext();
    if (!!record && !!record.allGroup) {
        return (<List2
            sx={{
                width: '100%',
                maxWidth: 360,
                bgcolor: 'background.paper',
                position: 'relative',
                overflow: 'auto',
                maxHeight: 50,
                '& ul': {padding: 0},
            }}
            subheader={<li/>}
        >
            {record[field.source].map((sectionId: any) => (
                <ListItem key={sectionId}>
                    <ListItemText primary={sectionId}/>
                </ListItem>
            ))}
        </List2>);
    } else {
        return (<span>无</span>)
    }
}
// const TagsField = (field: TagsFieldProps) => {
//
//     const record: any = useRecordContext();
//     console.log(record, !record.allGroup);
//     if (!!record && !!record.allGroup) {
//         return (<ul>
//             {
//                 record[field.source].map((group: string) => (
//                     <li key={group.toString()}>{group.toString()}</li>
//                 ))
//             }
//         </ul>);
//     } else {
//         return (<span>无</span>)
//     }
// }


export default LoadbalanceList;
