import { StoreMenusList } from './StoreMenusList';
import { StoreMenusCreate } from './StoreMenusCreate';
import { StoreMenusShow } from './StoreMenusShow';
import { StoreMenusEdit } from './StoreMenusEdit';
import {EditGuesser} from "react-admin";

export default {
    list: StoreMenusList,
    create: StoreMenusCreate,
    edit: StoreMenusEdit,
    show: StoreMenusShow,
};
