import * as React from 'react';
import {
    TextField,
    DateField,
    FunctionField,
    ReferenceField,
    EditButton,
    ShowButton,
    useRecordContext,
    ReferenceManyField,
    SingleFieldList,
    ChipField, useReference, useDelete, useNotify, useRedirect,
} from 'react-admin';

import {Box, Typography, Divider, Link, Button} from '@mui/material';

import {Users, Sale, Tenant} from '../types';
import {HashAvatarIcon} from "../HashAvatarIcon";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from '@mui/icons-material/Delete';

interface CompanyAsideProps {
    link?: string;
}

export const ConversionsAside = ({link = 'edit'}: CompanyAsideProps) => {
    const record = useRecordContext<Users>();
    let [del] = useDelete();
    let notice = useNotify();

    const redirect = useRedirect();
    if (!record) return null;
    const handleDelete = () => {
        if (window.confirm('确定删除吗？')) {
            del(`conversions`, {id: record.id.toString()}).then(() => {
                notice('删除成功');
                console.log('deleted', record.id.toString());
                redirect('/conversions');
            });
        } else {
            notice('取消删除');
        }
    }
    return (
        <Box ml={4} width={250} minWidth={250}>
            <Box textAlign="center" mb={2}>
                {link === 'edit' ? (
                    <EditButton label="编辑"/>
                ) : (
                    <ShowButton label="显示"/>
                )}
            </Box>
            <Box mb={2}>
                <IconButton color={"error"} onClick={handleDelete}><DeleteIcon/></IconButton>
            </Box>
            <Typography variant="subtitle2">资源信息</Typography>
            <Divider/>
            <Box mt={2}>
                <Typography variant="body2">
                    资源id：{record.id}
                </Typography>
            </Box>
            <Divider/>
            <Box mt={2}>
                <Divider/>

                <Typography variant="body2">
                    {"地址：" + record.pathFormat}
                </Typography>

                <Divider/>
                <Typography variant="body2">
                    {"缩写：" + record.category}
                </Typography>

                <Divider/>
                <Typography variant="body2">
                    {"资源类型：" + record.fileCategory}
                </Typography>

                <Divider/>
                <Typography variant="body2">
                    {"描述" + record.describe ?? ""}
                </Typography>

                <Divider/>
                <Typography variant="body2">
                    {"缓存Key：" + record.masterKey}
                </Typography>

                <Divider/>
                <Typography variant="body2">
                    {"资源数量：" + record.length}
                </Typography>
            </Box>

        </Box>
    );
};
const TenantList = () => {
    return (
        <SingleFieldList resource={"/tenant"} linkType={"show"}>
            <ChipField source="name"/>
        </SingleFieldList>
    )
}

