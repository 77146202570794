import * as React from 'react';
import {
    TextField,
    DateField,
    FunctionField,
    ReferenceField,
    EditButton,
    ShowButton,
    useRecordContext,
    ReferenceManyField,
    SingleFieldList,
    ChipField, useReference, ArrayField,
} from 'react-admin';

import {Box, Typography, Divider, Link, Button, Chip, Stack, Paper, styled, Tooltip} from '@mui/material';

import {Users, Sale, Tenant} from '../types';
import {HashAvatarIcon} from "../HashAvatarIcon";
import {HtmlHTMLAttributes} from "react";
import AddCardIcon from '@mui/icons-material/AddCard';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import GroupsIcon from '@mui/icons-material/Groups';
import pink from '@mui/material/colors/pink';

interface CompanyAsideProps {
    link?: string;
}

export const UserAside = ({link = 'edit'}: CompanyAsideProps) => {
    const record = useRecordContext<Users>();
    if (!record) return null;
    return (
        <Box ml={4} width={250} minWidth={250}>
            <Box textAlign="center" mb={2}>
                {link === 'edit' ? (
                    <EditButton label="编辑用户"/>
                ) : (
                    <ShowButton label="显示用户"/>
                )}
            </Box>

            <Typography component={"div"} variant="subtitle2">信息</Typography>
            <Divider/>
            <Box mt={2}>
                <Typography component={"div"} variant="body2">
                    用户：<Chip icon={<FingerprintIcon/>} size={"small"} label={record.id}/>
                </Typography>
                <Typography component={"div"} variant="body2">
                    名片：<Chip icon={<GroupsIcon/>} size={"small"} label={record.card}/>
                </Typography>
                <Typography component={"div"} variant="body2">
                    昵称：<Chip icon={<AddCardIcon/>} size={"small"} label={record.nickname}/>
                </Typography>
                <Typography component={"div"} variant="body2">
                    头衔：{record.title}
                </Typography>
                <Typography component={"div"} variant="body2">
                    价值：{record.money}
                </Typography>
                <Typography component={"div"} variant="body2">
                    评定：{record.moneyHelp}
                </Typography>
            </Box>
            <Divider/>
            <Box mt={2}>
                <Typography component={"div"} variant="body2">
                    当前积分：{record.score}
                </Typography>
                <Typography component={"div"} variant="body2">
                    总积分：{record.allScore}
                </Typography>
            </Box>
            <Divider/>
            <Box mt={2}>
                <Typography component={"div"} variant="body2">
                    发言次数：{record.speakCount}（↑0）
                </Typography>
                <Typography component={"div"} variant="body2">
                    签到次数：{record.signInCount}（↑0）
                </Typography>
                <Typography component={"div"} variant="body2">
                    签到第一：{record.signInNo1}（↑0）
                </Typography>
                {/*<Typography component={"div"} variant="body2">*/}
                {/*    回复效率：{record.reply}（↑0）*/}
                {/*</Typography>*/}
                {/*<Typography component={"div"} variant="body2">*/}
                {/*    本周回复效率：{record.weekReply}（↑0）*/}
                {/*</Typography>*/}
            </Box>
            <Divider/>
            <Box mt={2}>
                <Typography component={"div"} variant="body2">
                    当前段位：{record.warLevel}（↑0）
                </Typography>
                <Typography component={"div"} variant="body2">
                    最高段位：{record.warMaxLevel}（↑0）
                </Typography>
            </Box>
            <Divider/>
            <Box mt={2}>
                <Typography component={"div"} variant="body2">
                    战队赛场次：{record.warCount}（↑0）
                </Typography>
                <Typography component={"div"} variant="body2">
                    登记周数：{record.week}（↑0）
                </Typography>
            </Box>
            <Divider/>
            <Box mt={2}>
                <Typography component={"div"} variant="body2">
                    入群时间：{record.joinTime}（↑0）
                </Typography>
                <Typography component={"div"} variant="body2">
                    是否是主人：{(record.isMaster ? "是" : "否")}
                </Typography>
            </Box>
            <Divider/>
            <Box mt={2}>
                <Typography component={"div"} variant="body2">
                    对xx最热情：{record.likeWho && (record.likeWho?.nickName + "（" + (record.likeWho?.userId) + "）↑" + record.likeWho.count)}
                </Typography>
                <Typography component={"div"} variant="body2">
                    xx对其最热情：{record.likeWho && record.whoLike.nickName + "（" + (record.whoLike.userId) + "）↑" + record.whoLike.count}
                </Typography>
                <Typography component={"div"} variant="body2">
                    艾特人次数：{record.atUser}（↑0）
                </Typography>
                <Typography component={"div"} variant="body2">
                    被艾特次数：{record.userAt}（↑0）
                </Typography>
                <Typography component={"div"} variant="body2">
                    艾特不同人数：{record.atAny}（↑0）
                </Typography>
            </Box>
            <Divider/>
            <Box mt={2}>
                <Typography component={"div"} variant="body2">
                    <Chip size={"small"} label={`艾特列表：${record.userAt}`} color={"primary"}/>
                </Typography>
            </Box>
            <Box mt={2}>
                <Stack spacing={1}>
                    {record.atTop10 && record.atTop10.map((ui: any) => {
                        return <Tooltip key={ui.userId.toString()} title={ui.userId}><Item
                            > 【{(ui.nickName.length > 12 ? ui.nickName.substring(0, 12) + "..." : ui.nickName)}】 {ui.count}次</Item></Tooltip>;
                    })}</Stack>
            </Box>

        </Box>
    );
};

const Item = styled(Paper)(({theme}) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    // padding: theme.spacing(1),
    size: "small",
    textAlign: 'left',
    color: theme.palette.text.secondary,
}));

const TenantList = () => {
    return (
        <SingleFieldList resource={"/tenant"} linkType={"show"}>
            <ChipField source="name"/>
        </SingleFieldList>
    )
}

