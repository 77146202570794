import * as React from 'react';
import {Edit, Form, Toolbar} from 'react-admin';
import {
    Box,
    CardContent,
    Stack,
    Avatar,
    Card,
    CardActionArea,
    CardMedia,
    Typography,
    CardActions,
    Button, Divider
} from '@mui/material';

import {UserForm} from './UserForm';

import {UserAside} from './UserAside';
import {LogoField} from './LogoField';

export const UserEdit = () => {
    return (
        <Edit
            aside={<UserAside link="show"/>}
            actions={false}
            redirect="show"
        >
            <Form>
                <CardContent>
                    <Stack direction="row">
                        <Box ml={2} flex="1" maxWidth={796}>
                            <UserForm/>
                        </Box>

                    </Stack>
                </CardContent>
                <Toolbar/>
            </Form>
        </Edit>
    );
};
