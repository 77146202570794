import InvoiceIcon from '@mui/icons-material/LibraryBooks';

import {
    KnapsackList
} from './knapsackList';
import {EditGuesser, ListGuesser} from "react-admin"
import {KnapsackEdit} from "./KnapsackEdit";
import {KnapsackCreate} from "./KnapsackCreate";

export default {
    list: KnapsackList,
    icon: InvoiceIcon,
    edit: KnapsackEdit,
    create: KnapsackCreate
};
