import * as React from 'react';
import {Admin, Resource, ListGuesser} from 'react-admin';
import baseDataProvider from './dataProvider'
import {authProvider} from './authProvider';
import Layout from './Layout';
import users from './users';
import loadBalance from './loadBalance';
import knapsack from './knapsack';
import storeMenus from './storeMenus';
import signIn from './signIn';
import conversions from './packagebase';
import war from './war';
import speak from './speaks';
import conversionsPackage from './package';
import cache from './caches'
import groupSettings from './groupSettings';
import order from './order';
import language from './language'

import {Dashboard} from './dashboard/Dashboard';
import {darkTheme} from "./configuration/Configuration";
//import polyglotI18nProvider from 'ra-i18n-polyglot';
//import chinese from './chinese';

//const i18nProvider = polyglotI18nProvider(() => chinese  , 'ch');

const App = () => (
    <Admin
        dataProvider={baseDataProvider}
        authProvider={authProvider}
        //i18nProvider={i18nProvider}

        dashboard={Dashboard}
        layout={Layout}
        theme={darkTheme}
    >
        {/*各种统计信息*/}
        <Resource options={{label: "排行"}} name="order" {...order} />
        {/*成员信息*/}
        <Resource options={{label: "用户"}} name="users" {...users} />
        {/*比赛*/}
        <Resource options={{label: "比赛"}} name="war" {...war} />
        {/*负载信息*/}
        <Resource options={{label: "负载"}} name="loadBalance" {...loadBalance} />
        {/*商店*/}
        <Resource options={{label: "商城"}} name="storeMenus" {...storeMenus} />
        {/*背包*/}
        <Resource options={{label: "背包"}} name="knapsack" {...knapsack} />
        {/*积分*/}
        <Resource options={{label: "积分"}} name="signIn" {...signIn} />
        {/*转储资源，贴图信息*/}
        <Resource options={{label: "资源"}} name="conversions" {...conversions} />
        {/*转储资源，贴图信息*/}
        <Resource options={{label: "卡片"}} name="conversionsPackage" {...conversionsPackage} />
        {/*发言信息*/}
        <Resource options={{label: "发言"}} name="speak" {...speak} />
        {/*发言信息*/}
        <Resource options={{label: "群组"}} name="groupSettings" {...groupSettings} />
        {/*发言信息*/}
        {/*<Resource options={{label: "缓存"}} name="cache" {...cache} />*/}

        {/*<Resource options={{label: "语言包"}} name="language" {...language} />*/}

    </Admin>
);

export default App;
