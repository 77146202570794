import * as React from 'react';
import {
    List,
    Datagrid,
    DateField,
    ReferenceField,
    NumberField,
    DateInput,
    FunctionField,
    CreateButton,
    FilterButton,
    FilterForm,
    ListBase,
    Pagination,
    TextField,
    TextInput
} from 'react-admin';

import {Chip, Stack, Typography} from '@mui/material';

import KnapsackShow from './knapsackShow';
import {GroupUserAvatarIcon, HashAvatarIcon, JFSCAvatarIcon} from "../HashAvatarIcon";

const postFilters = [
    <TextInput label="Search" source="q" alwaysOn/>,
    <TextInput label="userId" source="userId" defaultValue=""/>,
    <TextInput label="title" source="title"/>,
];

const ListToolbar = () => (
    <Stack direction="row" justifyContent="space-between">
        <FilterForm filters={postFilters}/>
        <div>
            <FilterButton filters={postFilters}/>
            <CreateButton/>
        </div>
    </Stack>
)
export const KnapsackList = () => (
    <List
        filterDefaultValues={{search: 1}}
        perPage={15}
        pagination={<Pagination rowsPerPageOptions={[15, 30, 60, 120]}/>}
        sort={{field: 'createTime', order: 'desc'}}>
        <>
            <KnapsackRender/>
        </>
    </List>
);

interface DisKnapsack {
    disUser?: boolean | undefined;
}

export const KnapsackRender = (prop?: DisKnapsack | undefined) => {
    return <Datagrid bulkActionButtons={false} rowClick="edit">
        {/*<TextField source="id" />*/}
        {/*<TextField source="title" />*/}
        {/*<NumberField source="allowUseCount" />*/}
        <ReferenceField label={"用户"} source="userId" reference="users">
            <FunctionField render={(record: any) => {
                return (
                    <GroupUserAvatarIcon showId={true} size={30} {...record}/>
                )
            }}/>
        </ReferenceField>
        <ReferenceField label={"物品"} source="menuId" reference="StoreMenus">
            {/*<TextField source="title"/>*/}
            <FunctionField render={(record: any) => {
                return (
                    <Chip label={record.title}/>
                )
            }}/>
        </ReferenceField>
        <NumberField label={"数量"} source="count"/>
        <TextField label={"使用次数"} source="useCount"/>
        <DateField label={"最后使用"} source="lastUseTime"/>
        <DateField label={"创建时间"} source="createTime"/>
        {/*<DateField label={"更新时间"} source="updateTime"/>*/}
    </Datagrid>
}

export default KnapsackList;
