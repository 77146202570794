import {SimpleForm, Edit, TextInput, FunctionField, DateInput, NumberInput, Create} from 'react-admin'
import {Grid, Stack, Divider} from "@mui/material";
import {GroupDarkAppBar} from "../GroupDarkAppBar";
import * as React from "react";

export const GroupSettingEdit = () => (
    <Edit>
        <GroupSettingForm/>
    </Edit>
);

export const GroupSettingCreate = () => (
    <Create>
        <GroupSettingForm/>
    </Create>
);
export const GroupSettingForm = () => (
    <SimpleForm>
        <FunctionField source="id" render={(record: any) => <GroupDarkAppBar title={(record.name??"") + record.id}/>}/>

        <Grid item container direction="column">

            <Grid item md={6} container spacing={-2}>
                <Grid item md={3}>
                    <TextInput label={"普通签到"} source="signalMemberCategory"/>
                </Grid>
                <Grid item md={3}>
                    <TextInput label={"主人签到"} source="signalMasterCategory"/>
                </Grid>
            </Grid>

            <Divider/>

            <Grid item md={6} container spacing={-2}>
                <Grid item md={3}>
                    <NumberInput label={"通关级别"} source="questionLevel"/>
                </Grid>
                <Grid item md={3}>
                    <TextInput label={"排行榜图"} source="orderBy"/>
                </Grid>
            </Grid>

            <Divider/>

            <Grid item md={6}>
                <TextInput source="welcome" label={"入群"} multiline fullWidth/>
            </Grid>
            <Grid item md={6}>
                <TextInput source="goOut" label={"退群"} multiline fullWidth/>
            </Grid>

        </Grid>
    </SimpleForm>
);