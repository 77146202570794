import * as React from 'react';
import {useState} from 'react';
import {Paper, Typography, Link as MuiLink, Box, Divider, CardHeader, Avatar, Tooltip} from '@mui/material';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import HttpIcon from '@mui/icons-material/Http';
import {useCreatePath, SelectField, useRecordContext} from 'react-admin';
import {Link} from 'react-router-dom';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import {Users} from '../types';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import {HashAvatarIcon, JFSCAvatarIcon} from "../HashAvatarIcon";
import FlashOffIcon from '@mui/icons-material/FlashOff';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import {pink, red} from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import ApiIcon from '@mui/icons-material/Api';
import StorefrontIcon from '@mui/icons-material/Storefront';
import {StoreMenus} from "../types";
import SoupKitchenSharpIcon from '@mui/icons-material/SoupKitchenSharp';
import RemoveShoppingCartSharpIcon from '@mui/icons-material/RemoveShoppingCartSharp';
import ShoppingCartSharpIcon from '@mui/icons-material/ShoppingCartSharp';
import FaceSharpIcon from '@mui/icons-material/FaceSharp';
import FaceRetouchingOffSharpIcon from '@mui/icons-material/FaceRetouchingOffSharp';

export const sectors = [
    '普通应用',
    '特殊应用'
].map(sector => ({id: sector, name: sector}));

interface StoreMenusCardProps {
    height?: number;
    width?: number;
    JFSCAvatarIcon?: React.ReactElement;
}
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
export const StoreMenusCard = (prop: StoreMenusCardProps) => {
    const [elevation, setElevation] = useState(1);
    const createPath = useCreatePath();
    const record = useRecordContext<StoreMenus>();
    if (!record) return null;

    return (
        <MuiLink
            component={Link}
            to={createPath({
                resource: 'StoreMenus',
                id: record.id,
                type: 'edit',
            })}
            underline="none"
            onMouseEnter={() => setElevation(3)}
            onMouseLeave={() => setElevation(1)}
        >
            <Paper
                sx={{
                    height: prop.height ?? 500,
                    width: prop.width ?? 195,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    padding: '1em',
                }}
                elevation={elevation}>
                <Box display="flex" flexDirection="column" alignItems="center">
                    <CardHeader
                        title={record.title.length > 6 ? record.title.substring(0, 4) + "..." : record.title}
                    />
                    {prop.JFSCAvatarIcon ?? <JFSCAvatarIcon {...record}   />}
                    <Box textAlign="center" marginTop={1}>
                        <Typography variant="body2">
                            {(record.describe.length > 10 ? record.describe.substring(0, 10) + "..." : record.describe)}
                        </Typography>

                    </Box>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Tooltip title={"是否可以购买"}>
                            <IconButton size={"small"}>
                                {record.sale ?
                                    <ShoppingCartSharpIcon sx={{color: pink[500]}}/> :
                                    <RemoveShoppingCartSharpIcon/>
                                }
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={"是否可以使用"}>

                            <IconButton size={"small"}>
                                {
                                    record.use ?
                                        <FlashOnIcon sx={{color: pink[500]}}/> :
                                        <FlashOffIcon/>
                                }
                            </IconButton>

                        </Tooltip>
                        <Tooltip title={"是否可以交易"}>

                            <IconButton size={"small"}>
                                {
                                    record.trade ?
                                        <FaceSharpIcon sx={{color: pink[500]}}/> :
                                        <FaceRetouchingOffSharpIcon/>
                                }
                            </IconButton>

                        </Tooltip>
                    </Box>
                </Box>

                <Box display="flex" justifyContent="space-around" width="100%">
                    <Box display="flex" alignItems="center">
                        {record.score > 0
                            ? <AttachMoneyIcon color="success" sx={{mr: 1}}/>
                            : <AttachMoneyIcon color="disabled" sx={{mr: 1}}/>}

                        <Typography variant="caption" color="textSecondary">
                            {record.score > 0 ? record.score : '0'}
                        </Typography>
                    </Box>
                    <Box display="flex" alignItems="center">

                        {record.stock > 0
                            ? <StoreIcon color="primary" sx={{mr: 1}}/>
                            : <StoreIcon color="disabled" sx={{mr: 1}}/>}
                        <Typography variant="caption" color="textSecondary">
                            {record.stock > 0 ? record.stock : '0'}
                        </Typography>
                    </Box>

                </Box>
            </Paper>
        </MuiLink>
    );
};

import StoreIcon from '@mui/icons-material/Store';
