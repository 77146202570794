import * as React from 'react';
import {useState} from 'react';
import {Paper, Typography, Link as MuiLink, Box} from '@mui/material';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import HttpIcon from '@mui/icons-material/Http';
import {useCreatePath, SelectField, useRecordContext} from 'react-admin';
import {Link} from 'react-router-dom';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import {ConversionsPackage, Users} from '../types';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import {HashAvatarIcon, PokeAvatarIcon} from "../HashAvatarIcon";
import FlashOffIcon from '@mui/icons-material/FlashOff';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import {pink} from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import ApiIcon from '@mui/icons-material/Api';
import StorefrontIcon from '@mui/icons-material/Storefront';



export const ConversionsPackageCard = (props: { record?: ConversionsPackage }) => {
    const [elevation, setElevation] = useState(1);
    const createPath = useCreatePath();
    const record = useRecordContext<ConversionsPackage>(props);
    if (!record) return null;

    return (
        <MuiLink
            component={Link}
            to={
                createPath({
                    resource: 'conversions',
                    id: record.id,
                    type: 'show',
                })}
            underline="none"
            onMouseEnter={() => setElevation(3)}
            onMouseLeave={() => setElevation(1)}
        >
            <Paper
                sx={{
                    height: 200,
                    width: 195,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    padding: '1em',
                }}
                elevation={elevation}>
                <Box display="flex" flexDirection="column" alignItems="center">
                    <PokeAvatarIcon {...record} iconType={"application"}/>
                    <Box textAlign="center" marginTop={1}>
                        <Typography variant="subtitle2"
                        >
                            {record.card} ({record.id})
                        </Typography>

                    </Box>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <IconButton>
                            {
                                record.enable == 1 ?
                                    <FlashOnIcon sx={{color: pink[500]}}/> :
                                    <FlashOffIcon/>
                            }
                        </IconButton>
                    </Box>
                </Box>
                <Box display="flex" justifyContent="space-around" width="100%">
                    <Box display="flex" alignItems="center">

                        {record.length > 0
                            ? <ApiIcon color="success" sx={{mr: 1}}/>
                            : <ApiIcon color="disabled" sx={{mr: 1}}/>}

                        <Typography variant="caption" color="textSecondary">
                            {record.length > 0 ? record.length : '0'}
                        </Typography>
                    </Box>
                    <Box display="flex" alignItems="center">
                        {record.length > 0
                            ? <StorefrontIcon color="primary" sx={{mr: 1}}/>
                            : <StorefrontIcon color="disabled" sx={{mr: 1}}/>}
                        <Typography variant="caption" color="textSecondary">
                            {record.category}
                        </Typography>
                    </Box>

                </Box>
            </Paper>
        </MuiLink>
    );
};
