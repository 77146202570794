import InvoiceIcon from '@mui/icons-material/LibraryBooks';

import InterfaceList from './loadBalanceList';
import {ListGuesser} from "react-admin"
import LoadbalanceList from "./loadBalanceList";

export default {
    list: LoadbalanceList,
    icon: InvoiceIcon,
};
