import * as React from 'react';
import {Box, Card, CardContent, Grid, Typography} from '@mui/material';
import {ReferenceField, TextField, useRecordContext} from 'react-admin';


import {MetaInterfaceRelation} from '../types';

const InterfaceShow = () => {
    const record = useRecordContext<MetaInterfaceRelation>();
    if (!record) return null;
    return (
        <Card sx={{margin: 'auto'}}>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography variant="h6" gutterBottom align="center">
                            描述：{record.description}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>

                        <Typography variant="h6" gutterBottom align="center">
                            编号：{record.id}
                        </Typography>
                    </Grid>

                </Grid>
                <Box height={20}>&nbsp;</Box>
                {/*<Grid container spacing={2}>*/}
                {/*    <Grid item xs={6}>*/}
                {/*        <Typography variant="h6" gutterBottom align="center">*/}
                {/*            <ReferenceField source="metaId" label={"数据源"} reference="meta">*/}
                {/*                <TextField source="description"/>*/}
                {/*            </ReferenceField>*/}
                {/*        </Typography>*/}
                {/*    </Grid>*/}
                {/*    <Grid item xs={6}>*/}
                {/*        <Typography variant="h6" gutterBottom align="center">*/}
                {/*            {record.creator}*/}
                {/*        </Typography>*/}
                {/*    </Grid>*/}
                {/*</Grid>*/}
            </CardContent>
        </Card>
    );
};

// const CustomerField = () => {
//     const record = useRecordContext<Customer>();
//     return record ? (
//         <Typography>
//             {record.first_name} {record.last_name}
//             <br />
//             {record.address}
//             <br />
//             {record.city}, {record.zipcode}
//         </Typography>
//     ) : null;
// };

export default InterfaceShow;
