import * as React from 'react';
import {useForm} from 'react-hook-form';
import {
    Box,
    Button, Checkbox,
    InputAdornment,
    InputLabel, ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent, Theme, useTheme
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import {
    TextInput,
    FunctionField,
    NullableBooleanInput,
    useListContext,
    useGetList,
    SelectInput,
    ReferenceInput,
    Form
} from 'react-admin';

interface WeekTime {
    weekTime: any
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name: string, personName: string[], theme: Theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export const PostFilterForm = (wt: WeekTime) => {

    const {
        displayedFilters,
        filterValues,
        setFilters,
    } = useListContext();

    const [doubleWeek, setDoubleWeek] = React.useState<string>();
    const theme = useTheme();

    const [week, setWeek] = React.useState<string[]>([]);
    const handleChange = (event: SelectChangeEvent<typeof week>) => {
        const {
            target: {value},
        } = event;
        setWeek(typeof value === 'string' ? value.split(',') : value);
        filterValues.weekTime = value;
        setFilters(filterValues, []);
    };
    const doubleHandleChange = (event: SelectChangeEvent<typeof doubleWeek>) => {
        const {
            target: {value},
        } = event;
        setDoubleWeek(value);
        filterValues.doubleWeek = value;
        setFilters(filterValues, []);
    };

    return (
        <Form>
            <Box display="flex" alignItems="flex-end" mb={6}>
                <Box component="span" mr={6}>
                    <InputLabel id="demo-multiple-name-label">周筛</InputLabel>
                    <Select
                        sx={{width: 300}}
                        multiple
                        labelId="demo-multiple-name-label"
                        value={week}
                        onChange={handleChange}
                        input={<OutlinedInput label="周"/>}
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={MenuProps}
                    >
                        {wt.weekTime.map((weekCategory: any) => (
                            <MenuItem
                                key={weekCategory.id}
                                value={weekCategory.name}
                                style={getStyles(weekCategory, week, theme)}
                            >
                                <Checkbox checked={week.indexOf(weekCategory.name) > -1}/>
                                <ListItemText primary={weekCategory.name}/>
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
                <Box component="span" mr={6}>
                    <InputLabel id="demo-multiple-name-label">翻倍周</InputLabel>
                    <Select
                        sx={{width: 300}}
                        labelId="demo-multiple-name-label"
                        value={doubleWeek}
                        onChange={doubleHandleChange}
                        input={<OutlinedInput label="周"/>}
                        renderValue={(selected) => selected}
                        MenuProps={MenuProps}
                    >
                        {wt.weekTime.map((weekCategory: any) => (
                            <MenuItem
                                key={weekCategory.id}
                                value={weekCategory.name}
                                style={getStyles(weekCategory, [doubleWeek ?? ""], theme)}
                            >
                                <Checkbox checked={doubleWeek == (weekCategory.name)}/>
                                <ListItemText primary={weekCategory.name}/>
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
            </Box>
        </Form>
    );
};