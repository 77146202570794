import {fetchUtils} from 'react-admin';
import sirius_sdk from './sirius.sdk';
import {Options} from "ra-core";

export const httpClient = (url: any, options: Options = {}) => {

    let token = localStorage.getItem('token');
    let uid = localStorage.getItem('uid');
    let sirius_openid = localStorage.getItem('sirius_openid');
    let sirius_token = localStorage.getItem('sirius_token');
    let sirius_appid = localStorage.getItem('sirius_appid');
    if (!options.headers) {
        options.headers = new Headers(
            {Accept: 'application/json'}
        )
        options.headers.append('x-token', token ?? "");
        options.headers.append('x-uid', uid ?? "");
        //初始化租户用于初始化时配置一些简单接口, 在真正登录时才从登录环境获取最新的token信息
        options.headers.append('sirius_openid', sirius_openid ?? "OPEN9211662030339862");
        options.headers.append('sirius_token', sirius_token ?? "STT40812B189D");
        options.headers.append('sirius_appid', sirius_appid ?? "APP9211658828605894");
    }

    return fetchUtils.fetchJson(url, options).then(response => {
        const {headers, json} = response
        if (json.code !== 0 && !!json.message) {

            console.log(json.message);
        }
        return response;
    });
}

const siriusHttpClient = sirius_sdk(process.env.REACT_APP_SIRIUS_URL, httpClient)

export default siriusHttpClient