import * as React from 'react';
import {
    List,
    TopToolbar,
    ExportButton,
    CreateButton,
    Pagination,
    useGetIdentity,
} from 'react-admin';

import {ImageList} from './GridList';
import {UserListFilter} from './UserListFilter';

export const UserList = () => {
    const {identity} = useGetIdentity();
    if (!identity) return null;

    return (
        <List
            actions={<ApplicationListActions/>}
            aside={<UserListFilter/>}
            filterDefaultValues={{search: true}}
            perPage={28}
            pagination={<Pagination rowsPerPageOptions={[28, 40, 80, 100]}/>}
            sort={{field: 'name', order: 'ASC'}}
            component="div"
        >
            <ImageList/>
        </List>
    );
};

const ApplicationListActions = () => {
    return (
        <TopToolbar>
            <ExportButton label={"导出"}/>
            <CreateButton
                variant="contained"
                label="新建"
                sx={{marginLeft: 2}}
            />
        </TopToolbar>
    );
};
