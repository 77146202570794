import * as React from 'react';

import {
    TextInput,
    SelectInput,
    FileInput,
    FileField, useRecordContext,
} from 'react-admin';

import {Divider, Stack, Grid, Select, MenuItem, Container, Box, styled, Switch} from '@mui/material';
import {useState} from "react";

export const sectors = [
    {id: 'image', name: '图片'},
    {id: 'audio', name: '音频'},
    {id: 'video', name: '视频'},
];


export const ConversionsForm = ({checked, setChecked}: { checked: any, setChecked: any }) => {
    return (
        <>
            <Grid container spacing={2}>
                <Grid item md={3}>
                    <TextInput source="name" label={"素材名*"} placeholder={"活动专用图标"}/>
                    <Grid item md={6}></Grid>
                </Grid>
                <Divider/>
                <Grid item md={3}>
                    <TextInput source="category" label={"唯一标签名[Category]*"} placeholder={"JFSC"}/>
                </Grid>
                <Grid item md={6}></Grid>


                <Grid item md={6}>
                    <TextInput fullWidth={true} source="pathFormat" label={"存放地址"}
                               defaultValue={"image/packages/material/{0}"}/>
                </Grid>
                <Grid item md={6}></Grid>

                <Grid item md={3}>
                    <SelectInput source="fileCategory" label={"类别"} defaultValue={"image"} choices={sectors}
                                 optionText={w => w.name}/>
                </Grid>
                <Grid item md={3}>
                    <TextInput source="describe"/>
                </Grid>
                <Grid item md={6}></Grid>

                <Grid item md={3}>
                    <FileInput source="files" label="上传" accept="application/*"
                               placeholder={<p>点击</p>}>
                        <FileField source="src" title="title"/>
                    </FileInput>
                </Grid>
                <Grid item md={3}>
                    <Switch checked={checked} onChange={() => {
                        setChecked(!checked)
                    }}></Switch>
                </Grid>

            </Grid>
        </>
    );
}