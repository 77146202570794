import * as React from 'react';

import {
    ReferenceInput,
    TextInput,
    SelectInput,
    required,
    SelectField,
    SelectArrayInput,
    Pagination,
    NumberInput,
    BooleanInput,
    DateInput
} from 'react-admin';
import {Divider, Stack, Grid, Select, MenuItem} from '@mui/material';

export const sectors = [
    '普通应用',
    '特殊应用'
].map(sector => ({id: sector, name: sector}));

export const StoreMenusForm = () => (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}><TextInput variant={"outlined"} fullWidth source="id" disabled label={"编号"}/></Grid>
                <Grid item xs={4}><TextInput variant={"outlined"} source="title" label={"名称"}/></Grid>
                <Grid item xs={4}><TextInput variant={"outlined"} source="path" disabled label={"描述图片地址"}/> </Grid>
                <Grid item xs={4}><NumberInput variant={"outlined"} source="score" label={"价格"}/> </Grid>
                <Grid item xs={4}><NumberInput variant={"outlined"} source="cumulativeMultiple" label={"每多少次开始涨价"}/> </Grid>
                <Grid item xs={4}><TextInput variant={"outlined"} source="priceIncreaseRange" label={"涨价百分比"}/></Grid>
                <Grid item xs={4}><NumberInput variant={"outlined"} source="buyIndex" label={"购买次数"}/></Grid>
                <Grid item xs={4}><NumberInput variant={"outlined"} source="incrCount" label={"使用次数"}/></Grid>
                <Grid item xs={12}><TextInput variant={"outlined"} fullWidth source="describe" multiline rows={2} label={"描述"}/></Grid>
                <Grid item xs={4}><DateInput variant={"outlined"} source="createTime" label={"创建时间"} disabled/></Grid>
                <Grid item xs={4}><NumberInput variant={"outlined"} source="stock" label={"库存"}/></Grid>
                <Grid item xs={4}><NumberInput variant={"outlined"} source="orderBy" label={"排序"}/></Grid>
                <Grid item xs={4}><TextInput variant={"outlined"} source="linkDescribe" label={"存量最多者"} disabled/></Grid>
                <Grid item xs={4}><BooleanInput variant={"outlined"} source="isOnlyUseWeek"  label={"一周一次"}/></Grid>
                <Grid item xs={4}><BooleanInput variant={"outlined"} source="isOnlyUseDay" label={"一天一次"}/></Grid>
                <Grid item xs={4}><BooleanInput variant={"outlined"} source="trade"  label={"可交易"}/></Grid>
                <Grid item xs={4}><BooleanInput variant={"outlined"} source="sale"  label={"可购买"}/></Grid>
                <Grid item xs={4}><BooleanInput variant={"outlined"} source="use"  label={"可使用"}/></Grid>
                <Grid item xs={4}><BooleanInput variant={"outlined"} source="display" label={"商城显示"}/></Grid>
                <Grid item xs={4}><NumberInput variant={"outlined"} source="category" label={"类型"}/></Grid>
                <Grid item xs={4}><NumberInput variant={"outlined"} source="limit" label={"限购"}/></Grid>
            </Grid>

        </>
    )
;