export const getJFSC = (id: string) => {
    return `https://1-1251304948.cos.ap-guangzhou.myqcloud.com/inernoro/backups/store/main/${id}.jpg`;
}

export const getUserHead = (id: string) => {
    return `${getUserHeadPath()}/${id}.jpg`;
}
export const getUserHeadPath = () => {
    return `https://1-1251304948.cos.ap-guangzhou.myqcloud.com/inernoro/backups/head`;
}

export const getVol = (id: string) => {
    return `https://1-1251304948.cos.ap-guangzhou.myqcloud.com/inernoro/vol/${id}`;
}