import {Toolbar} from "react-admin";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import {AppBar, createTheme, Stack, ThemeProvider, Typography} from "@mui/material";
import * as React from "react";

function appBarLabel(label: string) {
    return (
        <Toolbar>
            <IconButton edge="start" color="inherit" aria-label="menu" sx={{mr: 2}}>
                <MenuIcon/>
            </IconButton>
            <Typography variant="h6" noWrap component="div" sx={{flexGrow: 1}}>
                {label}
            </Typography>
        </Toolbar>
    );
}

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#1976d2',
        },
    },
});

interface TitleProp {
    title: string
}

export const GroupDarkAppBar = (titleProp: TitleProp) => {
    return (
        <Stack spacing={2} sx={{flexGrow: 1}}>
            <ThemeProvider theme={darkTheme}>
                <AppBar position="static" color="primary">
                    {appBarLabel(titleProp.title)}
                </AppBar>
            </ThemeProvider>
        </Stack>
    );
}