import * as React from 'react';
import {
    FilterList,
    FilterLiveSearch,
    FilterListItem,
    useGetIdentity,
} from 'react-admin';
import {Box} from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';

import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';

export const UserListFilter = () => {
    const {identity} = useGetIdentity();
    return (
        <Box width="13em" minWidth="13em" order={-1} mr={2} mt={7}>
            <FilterLiveSearch/>
        </Box>
    );
};
