import {
    Datagrid, DateField, DateInput, List, NumberField, NumberInput, ReferenceField, ReferenceInput,
    SelectInput, Edit,
    SimpleForm, TextField, TextInput, FunctionField, useGetOne, useRecordContext, RecordContextProvider
} from "react-admin";
import {GroupUserAvatarIcon, HashAvatarIcon, JFSCAvatarIcon, JFSCAvatarIcon2} from "../HashAvatarIcon";
import {StoreMenus} from "../types";

import {CardContent, Stack, Avatar, Box, Card, CardActionArea, CardMedia, Typography, Divider} from '@mui/material';

const PackageAvatar = (record: any) => {
    console.log(record);
    if (!record) {
        return null
    }
    const {data: b} = useGetOne("storeMenus/title", {id: record.title});
    if (!b) {
        return null
    }
    console.log(b);
    return <JFSCAvatarIcon {...b} height={600}/>
}

export const KnapsackEdit = () => {
    return (
        <Edit>
            <CardContent>
                <Stack direction="row">

                    <Box ml={2} flex="1" maxWidth={796}>
                        <SimpleForm>
                            <TextInput source="title"/>
                            <NumberInput source="count"/>
                            <NumberInput source="useCount"/>
                            <NumberInput source="allowUseCount"/>
                            <DateInput source="lastUseTime"/>
                            <DateInput source="category"/>
                            <DateInput source="createTime"/>
                            <TextInput source="describe"/>
                        </SimpleForm>
                    </Box>
                    <Card sx={{maxWidth: 400, maxHeight: 600}}>
                        <CardActionArea>
                            <FunctionField render={(record: any) => {
                                console.log(record)
                                return <PackageAvatar {...record}/>
                            }}/>
                        </CardActionArea>
                    </Card>
                </Stack>
            </CardContent>
        </Edit>
    )
};