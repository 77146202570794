import * as React from 'react';
import Box from '@mui/material/Box';
import {DataGrid, GridColDef, GridValueGetterParams, GridColumnHeaderParams} from '@mui/x-data-grid';
import {useGetList} from "react-admin";
import {GroupIconAvatarIcon, GroupUserAvatarIcon} from "../HashAvatarIcon";
import {Tooltip} from "@mui/material";
import {pink} from "@mui/material/colors";
import Button from "@mui/material/Button";
import GroupIcon from '@mui/icons-material/Group';
import {getUserHead} from "../common/group";


const getColumns = (sortHeader: React.ReactNode, valueHeader: React.ReactNode) => {
    const columns: GridColDef[] = [
        {
            field: 'id',
            width: 150,
            renderCell: (params: GridValueGetterParams) => {
                return GroupIconAvatarIcon(getUserHead(params.row.id), params.row.name, params.row.id, 20)
            },
            renderHeader: (params: GridColumnHeaderParams) => {
                return (sortHeader)
            }
        },
        {
            field: 'count', width: 150, renderHeader: (params: GridColumnHeaderParams) => {
                return (valueHeader)
            }
        },
    ];
    return columns;
}

interface SortProps {
    source: string;
    sortHeader: React.ReactNode;
    valueHeader: React.ReactNode;
}

export default function WarSort(SortProps: SortProps) {
    const {data: users} = useGetList(SortProps.source);
    if (!users) {
        return null;
    }
    return (
        <Box sx={{width: '100%'}}>
            <DataGrid
                sx={{height: 550}}
                rows={users}
                columns={getColumns(SortProps.sortHeader, SortProps.valueHeader)}
                pageSize={13}
                rowsPerPageOptions={[13]}
                checkboxSelection
                disableSelectionOnClick
            />
        </Box>
    );
}