import * as React from 'react';
import {
    Create,
    SimpleForm,
    Form,
    useCreate,
    SaveButton,
    ListButton,
    ShowButton,
    Toolbar,
    TopToolbar,
    NumberInput,
    TextInput,
    useDataProvider, useRedirect, useNotify, useUpdate
} from 'react-admin';
import BusinessIcon from '@mui/icons-material/Business';
import {
    CardContent,
    Stack,
    Avatar,
    Box,
    Card,
    CardActionArea,
    CardMedia,
    Typography,
    Divider,
    AppBar,
    createTheme,
    ThemeProvider, Button
} from '@mui/material';

import {ConversionsForm} from './ConversionsForm';
import IconButton from "@mui/material/IconButton";
import MenuIcon from '@mui/icons-material/Menu';
import {GroupDarkAppBar} from "../GroupDarkAppBar";
import {useParams} from "react-router";
import sirius_sdk from "../sirius.sdk";
import {httpClient} from "../dataProvider";
import {object} from "prop-types";

interface UpButton {
    label: string;
}


const UploadSource = (prop: UpButton) => {

    const handleChange = () => {

    }
    return (<Button onClick={handleChange} variant="outlined">{prop.label}</Button>);
}


const PostEditActions = () => {

    return (<Toolbar>
            <SaveButton
                label="保存"
                variant="text"
                alwaysEnable
            />
        </Toolbar>
    );
}

export const CreateRequest = (params: any, type: string) => {
    const formData = new FormData();
    for (let key in params) {
        let value = params[key];
        if (key == "files") {
            formData.append('files', params.files.rawFile);
        } else {
            formData.append(key, value);
        }
    }

    return httpClient(`group/conversions/${type}`, {
        method: "POST",
        body: formData,
    })
}

export const ConversionsCreate = () => {

    const {id} = useParams();
    const [create] = useCreate();
    const redirect = useRedirect();
    const notify = useNotify();
    const [check, setCheck] = React.useState(null);

    const postSave = (data: any) => {
        if (data.files) {
            console.log("开始上传", data, id);
            let type = "upload";
            if (check) {
                type = 'uploadByGroup'
            }
            let dc = CreateRequest(data, type);
            dc.then((outputStr: any) => {
                let output = JSON.parse(outputStr.body);
                let fileName = output.fileName;
                data.id = data.category;
                data.conversionsBase = output.preBase;
                data.conversionsPackages = output.preData;
                data.packageId = output.uploadKey;
                console.log("发送的数据为", data);
                create('conversions', {data: data}, {
                    onSuccess: (dt: any) => {
                        redirect('show', 'conversions', dt.id);
                        notify('全部修改成功');
                        console.log("全部修改成功1", dt);
                    }
                })
            })
        } else {
            console.log("没文件你传个JS")
        }
    };

    return (
        <Create
            redirect="show"
        >
            <>
                <GroupDarkAppBar title={"创建素材"}/>
                <Create>
                    <SimpleForm
                        onSubmit={postSave}
                        toolbar={<PostEditActions/>}>
                        <CardContent>
                            <Stack direction="column">
                                <Box ml={2} flex="1" maxWidth={796}>
                                    <ConversionsForm checked={check} setChecked={setCheck}/>
                                </Box>
                            </Stack>
                        </CardContent>
                    </SimpleForm>
                </Create>
            </>
        </Create>
    );
}
