import * as React from 'react';
import {useState, ChangeEvent} from 'react';
import {
    ShowBase,
    ReferenceManyField,
    useShowContext,
    Datagrid,
    TextField,
    ReferenceField,
    Pagination, FunctionField, SingleFieldList, ChipField, UrlField
} from 'react-admin';

import {
    Box,
    Card,
    CardContent,
    Tabs,
    Tab,
    Divider, Stack, Button,
} from '@mui/material';
import {Link, Link as RouterLink} from 'react-router-dom';

import {StoreMenusAside} from './StoreMenusAside';
import {Users} from '../types';
import {HashAvatarIcon} from "../HashAvatarIcon";

export const StoreMenusShow = () => (
    <ShowBase>
        <StoreMenusShowContent/>
    </ShowBase>
);

const StoreMenusShowContent = () => {

    const {record, isLoading} = useShowContext<Users>();
    const [tabValue, setTabValue] = useState(0);
    const handleTabChange = (event: ChangeEvent<{}>, newValue: number) => {
        setTabValue(newValue);
    };
    // console.log(record);
    if (!record) return null;
    return (
        <Box mt={2} display="flex">
            <Box flex="1">
                <Card>
                    <CardContent>
                        <Tabs
                            value={tabValue}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={handleTabChange}>
                            {/*{*/}
                            {/*    <Tab*/}
                            {/*        label={"授权信息"}*/}
                            {/*    />*/}

                            {/*}*/}
                            {
                                <Tab
                                    label={"数据源"}
                                />

                            }
                            {
                                <Tab
                                    label={"接口"}
                                />

                            }

                        </Tabs>
                        <Divider/>

                        <TabPanel value={tabValue} index={0}>
                            <ReferenceManyField
                                reference={"appMeta/" + record.id}
                                perPage={15}
                                pagination={<Pagination rowsPerPageOptions={[15, 30, 60, 120]}/>}
                                filter={{search: true}}
                                target="id"
                                source="id"
                                sort={{field: 'updateTime', order: 'DESC'}}
                            >
                                <Datagrid>

                                    <FunctionField
                                        render={
                                            (record: any) => {
                                                return <Button variant="text" size={"small"} component={Link}
                                                               to={("/meta/"+record.id+"/show")}
                                                               startIcon={<HashAvatarIcon size={"small"} {...record} iconType={"meta"} id={record.id}/>}
                                                >
                                                    {record.description}</Button>
                                            }
                                        }
                                    />
                                    <TextField source="team" label={"团队"}/>
                                    <TextField source="collection" label={"小组"}/>
                                    <TextField source="project" label={"项目"}/>
                                    <TextField source="branch" label={"分支"}/>
                                    <TextField source="version" label={"版本"}/>
                                </Datagrid>
                            </ReferenceManyField>
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                            <ReferenceManyField
                                reference={"app_url/" + record.id}
                                perPage={15}
                                pagination={<Pagination rowsPerPageOptions={[15, 30, 60, 120]}/>}
                                filter={{search: true}}
                                target="id"
                                source="id"
                                sort={{field: 'updateTime', order: 'DESC'}}
                            >
                                <Datagrid
                                    expandSingle
                                    rowClick="expand"
                                    sx={{
                                        '& .column-customer_id': {
                                            display: {xs: 'none', md: 'table-cell'},
                                        },
                                        '& .column-total_ex_taxes': {
                                            display: {xs: 'none', md: 'table-cell'},
                                        },
                                        '& .column-delivery_fees': {
                                            display: {xs: 'none', md: 'table-cell'},
                                        },
                                        '& .column-taxes': {
                                            display: {xs: 'none', md: 'table-cell'},
                                        },
                                    }}
                                >
                                    <ReferenceField source="metaId" label={"数据源"} reference="meta">
                                        <TextField source="description"/>
                                    </ReferenceField>
                                    <TextField source="url"/>
                                    <ReferenceField source="appId" label={"应用"} reference="application">
                                        <TextField source="appName"/>
                                    </ReferenceField>
                                    <TextField source="createTime" label={"创建时间"}/>
                                    <TextField label={"创建者"} source="creator"/>
                                </Datagrid>
                            </ReferenceManyField>
                        </TabPanel>
                    </CardContent>
                </Card>
            </Box>
            <StoreMenusAside/>
        </Box>
    );
};

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

const TabPanel = (props: TabPanelProps) => {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            aria-labelledby={`wrapped-tab-${index}`}
            {...other}
        >
            {children}
        </div>
    );
};


