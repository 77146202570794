import {
    NumberInput, TextInput, useGetOne, Form, Create, FunctionField, SimpleForm
} from "react-admin";
import {JFSCAvatarIcon} from "../HashAvatarIcon";
import {StoreMenus} from "../types";

import {CardContent, Stack, Avatar, Box, Card, CardActionArea, CardMedia, Typography, Divider} from '@mui/material';
// import {Create} from "@mui/icons-material";
import {UsersSelect} from "../users/UsersSelect";
import UserSelectData from "./UserSelectData";
import TitleSelectData from "./TitleSelectData";

const PackageAvatar = (record: any) => {
    console.log(record);
    if (!record) {
        return null
    }
    const {data: b} = useGetOne("storeMenus/title", {id: record.title});
    if (!b) {
        return null
    }
    console.log(b);
    return <JFSCAvatarIcon {...b} height={600}/>
}

export const KnapsackCreate = () => {
    return (
        <Create>
            <Stack direction="row" sx={{height:900}}>
                <Box flex="1" maxWidth={400} sx={{height: 900}} height={900}>
                    {/*<SimpleForm>*/}
                    {/*    <TextInput source="title"/>*/}
                    {/*    <UsersSelect/>*/}
                    {/*    <NumberInput source="count"/>*/}
                    {/*</SimpleForm>*/}
                    <UserSelectData/>
                </Box>
                <Box flex="1" maxWidth={500}>
                    <TitleSelectData/>
                </Box>
                <Box flex="1" maxWidth={500}>
                    <UserSelectData/>
                </Box>

                {/*<Card sx={{maxWidth: 400, maxHeight: 600}}>*/}
                {/*    <CardActionArea>*/}
                {/*        <FunctionField render={(record: any) => {*/}
                {/*            console.log(record)*/}
                {/*            return <PackageAvatar {...record}/>*/}
                {/*        }}/>*/}
                {/*    </CardActionArea>*/}
                {/*</Card>*/}
            </Stack>
        </Create>
    )
};