import * as React from 'react';
import {
    Edit,
    Form,
    ListButton,
    TopToolbar,
    ShowButton,
    Toolbar,
    useCreate,
    useRedirect,
    useNotify,
    useUpdate
} from 'react-admin';
import {
    Box,
    CardContent,
    Stack,
    Avatar,
    Card,
    CardActionArea,
    CardMedia,
    Typography,
    CardActions,
    Button, Divider
} from '@mui/material';

import {ConversionsForm} from './ConversionsForm';

import {ConversionsAside} from './ConversionsAside';
import {LogoField} from './LogoField';
import {useParams} from "react-router";
import {CreateRequest} from "./ConversionsCreate";


export const ConversionsEdit = () => {
    const {id} = useParams();
    const [update] = useUpdate();
    const redirect = useRedirect();
    const notify = useNotify();
    const [check, setCheck] = React.useState(null);

    const postSave = (data: any) => {
        if (data.files) {
            console.log("开始上传", data, id);
            let type = "upload";
            if (check) {
                type = 'uploadByGroup'
            }
            let dc = CreateRequest(data, type);
            dc.then((outputStr: any) => {
                let output = JSON.parse(outputStr.body);
                let fileName = output.fileName;
                data.id = data.category;
                data.conversionsBase = output.preBase;
                data.conversionsPackages = output.preData;
                data.packageId = output.uploadKey;
                console.log("发送的数据为", data);
                update('conversions', {data: data}, {
                    onSuccess: (dt: any) => {
                        redirect('show', 'conversions', dt.id);
                        notify('全部修改成功');
                        console.log("全部修改成功1", dt);
                    }
                })
            })
        } else {
            console.log("没文件你传个JS")
        }
    };
    return (
        <Edit
            aside={<ConversionsAside link="show"/>}
            // actions={false}
            redirect="show"
            actions={false}
        >
            <Form onSubmit={postSave}>
                <CardContent>
                    <Stack direction="row">
                        <Box ml={2} flex="1" maxWidth={796}>
                            <ConversionsForm checked={check} setChecked={setCheck}/>
                        </Box>

                    </Stack>
                </CardContent>
                <Toolbar/>
            </Form>
        </Edit>
    );
};
