import * as React from 'react';
import {
    List,
    TopToolbar,
    ExportButton,
    CreateButton,
    Pagination,
    useGetIdentity,
} from 'react-admin';

import {ImageList} from './GridList';

export const StoreMenusList = () => {
    const {identity} = useGetIdentity();
    if (!identity) return null;

    return (
        <List
            // actions={<ApplicationListActions/>}
            // aside={<StoreMenusListFilter/>}
            filterDefaultValues={{search: true}}
            perPage={21}
            pagination={<Pagination rowsPerPageOptions={[21, 30, 60, 120]}/>}
            sort={{field: 'name', order: 'ASC'}}
            component="div"
        >

            <ImageList/>
        </List>
    );
};

const ApplicationListActions = () => {
    return (
        <TopToolbar>
            <ExportButton label={"导出"}/>
            <CreateButton
                variant="contained"
                label="创建卡片"
                sx={{marginLeft: 2}}
            />
        </TopToolbar>
    );
};
