import * as React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    ReferenceField,
    NumberField,
    DateInput,
    FunctionField,
    BooleanField, Pagination, useGetOne, useCreate,
} from 'react-admin';

import SpeakShow from './SpeakShow';
import {GroupUserAvatarIcon, HashAvatarIcon} from "../HashAvatarIcon";
import {Button, ButtonGroup, Chip, Divider, Tooltip, Typography} from "@mui/material";
import {Users, SigIn} from "../types"
import DarkModeIcon from "@mui/icons-material/DarkMode";
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
//结算
import DiamondIcon from '@mui/icons-material/Diamond';
//灯泡
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
//speck
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
//sigin
import PersonPinIcon from '@mui/icons-material/PersonPin';
//war
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import GroupIcon from '@mui/icons-material/Group';
import IconButton from "@mui/material/IconButton";
import MergeTypeIcon from '@mui/icons-material/MergeType';

import SendIcon from '@mui/icons-material/Send';

const listFilters = [
    <DateInput source="date_gte" alwaysOn/>,
    <DateInput source="date_lte" alwaysOn/>,
];

export interface Show {
    disableUserid?: boolean
    disableType?: boolean
    textLength?: number
}

export const Builders = (show: Show) => {
    const [create] = useCreate();
    return (<Datagrid bulkActionButtons={false}>
        <FunctionField label={"时间"} render={(record: any) => {
            return (
                <Typography component={"div"} variant="body2" color="text.secondary">
                    <ReferenceField link={"show"} source="user_id" reference="users">
                        <Tooltip title={record.user_id}><Chip variant={"outlined"}
                                                              icon={<GroupUserAvatarIcon {...record.sender}/>}
                                                              label={<TextField source="card"/>}></Chip></Tooltip>
                    </ReferenceField>
                </Typography>
            )
        }
        }></FunctionField>

        {/*<TextField source="id"/>*/}
        <FunctionField render={(record: any) => {
            const length = show.textLength ?? 50
            return record.raw_message.length > length ? record.raw_message.substring(0, length) + "..." : record.raw_message;
        }}/>

        <FunctionField label={""} render={(record: any) => {
            const reSend = () => {
                if (confirm("确定要重发吗？")) {
                    create("speak/reSend", {data: {id: record.id}});
                }
            }
            return (<>
                <ButtonGroup size="small" variant="text" aria-label="small button group">
                    <Button onClick={reSend} size="small" variant="outlined" startIcon={<SendIcon color={"primary"}/>}>
                        重发
                    </Button>
                    <Button size="small" variant="outlined" startIcon={<GroupIcon/>}>
                        {record.group_id}
                    </Button>
                    <Button size="small" variant="outlined" startIcon={<MergeTypeIcon/>}>
                        {record.message_type}
                    </Button>
                </ButtonGroup>
            </>)
        }}/>

        <FunctionField render={(record: any) => {
            let date = new Date(record.time * 1000);
            let YY = date.getFullYear();
            let MM = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
            let DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
            let hh = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
            let mm = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
            let ss = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
            let t = YY + "-" + MM + "-" + DD + " " + hh + ":" + mm + ":" + ss;
            return <Button size="small">{t}</Button>;
        }}/>

        {/*<ReferenceField source="self_id" reference="users">*/}
        {/*    <FunctionField render={(record: Users) => {*/}
        {/*        return (<Chip label={record.card}/>)*/}
        {/*    }}/> */}
        {/*</ReferenceField>*/}
        {/*<TextField source="message_type"/>*/}
        {/*<TextField source="sub_type"/>*/}
        {/*<TextField source="message"/>*/}
        {/*<NumberField source="message_seq"/>*/}
        {/*<ReferenceField source="message_id" reference="messages"><TextField source="id"/></ReferenceField>*/}
    </Datagrid>);
}
export const SpeakList = () => (
    <List perPage={15}
          filterDefaultValues={{search: true}}
          sort={{field: 'time', order: 'desc'}}
          pagination={<Pagination rowsPerPageOptions={[15, 30, 60, 120]}/>}>
        <Builders/>
    </List>
);


export default SpeakList;
