import * as React from 'react';
import {Edit, Form, Toolbar, useEditContext, useRecordContext} from 'react-admin';
import {
    Box,
    CardContent,
    Stack,
    Avatar,
    Card,
    CardActionArea,
    CardMedia,
    Typography,
    CardActions,
    Button, Divider, Paper, CardHeader
} from '@mui/material';

import {StoreMenusForm} from './StoreMenusForm';

import {LogoField} from './LogoField';
import {JFSCAvatarIcon2, VolAvatar} from "../HashAvatarIcon";
import {useContext} from "react";
import {StoreMenusCard} from "./StoreMenusCard";
import {useForm} from "react-hook-form";
import {getJFSC} from "../common/group";

export const StoreMenusEdit = () => {
    return (
        <Edit
            // aside={<StoreMenusAside link="show"/>}
            actions={false}
            redirect="list"
        >
            <Form>
                <CardContent>
                    <Stack direction="row" spacing={10}>
                        <Box ml={2} flex="1" maxWidth={796}>
                            <StoreMenusForm/>
                        </Box>
                        <Box ml={2} flex="1" maxWidth={400}>
                            <Paper elevation={3}>

                                <StoreEditRight/>

                            </Paper>
                        </Box>
                    </Stack>
                </CardContent>
                <Toolbar/>
            </Form>
        </Edit>
    );
};

const StoreEditRight = () => {
    const {path: id, title} = useRecordContext();
    const path = getJFSC(id);
    return <StoreMenusCard height={800} width={400}
                           JFSCAvatarIcon={<CardMedia component="img" height={600} image={path}/>}/>
}
