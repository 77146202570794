import * as React from 'react';
import {Edit, Form, Toolbar} from 'react-admin';
import {
    Box,
    CardContent,
    Stack,
    Avatar,
    Card,
    CardActionArea,
    CardMedia,
    Typography,
    CardActions,
    Button, Divider
} from '@mui/material';

import {ConversionsPackageForm} from './ConversionsPackageForm';

import {ConversionsPackageAside} from './ConversionsPackageAside';

export const ConversionsPackageEdit = () => {
    return (
        <Edit
            aside={<ConversionsPackageAside link="show"/>}
            actions={false}
            redirect="show"
        >
            <Form>
                <CardContent>
                    <Stack direction="row">
                        <Box ml={2} flex="1" maxWidth={796}>
                            <ConversionsPackageForm/>
                        </Box>

                    </Stack>
                </CardContent>
                <Toolbar/>
            </Form>
        </Edit>
    );
};
